import React from 'react';
import LogoutButton from "./logoutButton";
import {UserOutlined} from "@ant-design/icons";
import {Popover, Select} from "antd";

const AuthNav = ({userData}) => {
    return (
        <div>
            {userData &&
                <Popover content={
                    <div className="navbar-nav ml-auto">
                        { userData.currentAccount &&
                            <Select
                                defaultValue={userData.currentAccountDisplay}
                                style={{width: 180}}
                                showSearch={true}
                                options={userData.authorizedAccounts.map((name) => {
                                    return {value: name, label: name}
                                })}
                            />
                        }
                        <br/>
                        <br/>
                        <LogoutButton/>
                    </div>
                } title={userData.email}>
                    <UserOutlined className={"avatar"} style={{color: 'white', fontSize: 30}}/>
                </Popover>
            }
        </div>
    );
}

export default AuthNav;