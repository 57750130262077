// ThreadsExplorer.js
import React, { useState, useEffect } from "react";
import { Alert, Button, Select, Typography, Row } from 'antd';
import { Link } from "react-router-dom";
import {InfoCircleOutlined, ReloadOutlined} from "@ant-design/icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import dayjs from "dayjs";
import ThreadsExplorerBase from '../Orders/ThreadsExplorerBase';
import Workflow from '../Orders/Workflow';
import { fetchEventLogs } from "../../endpoints/fetchEventLogs";
import { useOutletContext } from 'react-router-dom';
import { volumeFormatter } from "../utils";
import Sparkles from "../Sparkles";
import Loading from "../Loading";


const IngestLog = (props) => {
  const { userData, setHasError } = props;
  const { accessToken } = userData;

  const [products, setProducts, orderUnit, setOrderUnit, custConfigs, accountConfig, setAccountConfig] = useOutletContext();

  const [dataSource, setDataSource] = useState(null);
  const [forReviewCount, setForReviewCount] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [pollingTaskId, setPollingTaskId] = useState(null);
  const [threadMessages, setThreadMessages] = useState([]);
  const [selectedThread, setSelectedThread] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [generatedOrder, setGeneratedOrder] = useState(null);
  const [threadTaskStatuses, setThreadTaskStatuses] = useState({});
  const [workflowState, setWorkflowState] = useState({
    products: [],
    generatedOrder: null,
    selectedMessage: null,
    selectedThread: null,
  });
  const [showNewTasksOnly, setShowNewTasksOnly] = useState(true);
  const [workflowVisible, setWorkflowVisible] = useState(false);
  const [workflowConfig, setWorkflowConfig] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(null);
  const [showFailedAlert, setShowFailedAlert] = useState(null);
  const [fullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    setWorkflowState({
      products: products,
      generatedOrder: generatedOrder,
      selectedMessage: selectedMessage,
      selectedThread: selectedThread,
    });

  }, [products, generatedOrder, selectedMessage, selectedThread]);

  useEffect(() => {
    if (!selectedThread) return;

    setThreadTaskStatuses((prevStatuses) => ({
        ...prevStatuses,
        [selectedThread.ThreadId]: { ...selectedThread.properties},
    }));
  }, [selectedThread]);

  useEffect(() => {
    if (showSuccessAlert) {
      const timer = setTimeout(() => {
        setShowSuccessAlert(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
    if (showFailedAlert) {
      const timer = setTimeout(() => {
        setShowFailedAlert(null);
      }, 60000);
      return () => clearTimeout(timer);
    }
  }, [showSuccessAlert, showFailedAlert]);

  const onThreadSelect = async (thread) => {
    const taskStatus = await fetchData({
      info_type: 'task_poll',
      task_id: selectedThread.ThreadId,
    });
    
    setThreadTaskStatuses((prevStatuses) => ({
      ...prevStatuses,
      [selectedThread.ThreadId]: { ...taskStatus['processing_result'] },
    }));
    setSelectedThread(thread);
  }

  const fetchData = async (info_description, raise_anyway = false) => {
    let data;
    try {
      data = await fetchEventLogs(
        accessToken,
        { ...info_description },
      );
    } catch (e) {
      setHasError(e);
      if (raise_anyway) throw e;
      console.log('err')
      console.log(e);
    }
    if (userData.email && userData.email.includes('@pantry'))
      console.log(data)

    return data;
  };

  const startCreateOrderWorkflow = async () => {
    const relevantAttachment = (threadMessages ?? [])
      .map((m) => m.attachments)
      .flat()
      .find((a) => a?.data_classification && a?.data_classification !== 'OTHER');
    
    setGeneratedOrder(null); // show loading screen
    try {
      const result = await fetchData(
        { info_type: 'generate_order', entity: {entity_type: 'integration_data', ...relevantAttachment} },
        true
      );
      if (result !== 'aborted' && result.error) {
        setGeneratedOrder({ error: result.error });
      }
      
      setPollingTaskId(result.task_id);
      
      // Update task status to indicate processing has started
      setThreadTaskStatuses((prevStatuses) => ({
        ...prevStatuses,
        [selectedThread.ThreadId]: { ...result['processing_result'] },
      }));
    } catch (e) {
      setGeneratedOrder({ error: e });
    }
  };


  const handleWorkflowFinish = async (formState, dismiss) => {

  };

  const toggleFullScreen = () => {
    const newFullScreen = !fullScreen;
    localStorage.setItem('pantry-fullScreen', JSON.stringify(newFullScreen));
    setFullScreen(newFullScreen);
  };

  const handleWorkflowCancel = () => {
    setWorkflowVisible(false);
    setWorkflowConfig(null);
    setFullScreen(false);
  };

  const handleMessageClick = async (message, selectedType) => {
    if (!workflowVisible) {
      setGeneratedOrder(null);
      setSelectedMessage({ ...message, body: null, entity_type: selectedType });
  
    } else {
      console.log('Already in workflow, cannot select new unless finish or cancel');
    }
  };

  const dedupThreads = (existingThreads, newThreads) => {
    const mergedThreads = [...existingThreads, ...(newThreads ? newThreads : [])];
    const uniqueThreads = [];
    const threadIds = new Set();

    for (const thread of mergedThreads) {
      if (!threadIds.has(thread.ThreadId)) {
        uniqueThreads.push(thread);
        threadIds.add(thread.ThreadId);
      }
    }

    return uniqueThreads;
  };

  const loadMore = () => {
    setLoadingMore(true);
    fetchData({ 'info_type': 'threads', 'paginationKey': dataSource.paginationKey, 'showDismissed': !showNewTasksOnly })
      .then((newData) => {
        if (newData) {
          setDataSource({
            ...dataSource,
            threads: dedupThreads(dataSource.threads, newData.threads).sort((a, b) => dayjs(b.Timestamp).diff(dayjs(a.Timestamp))),
            paginationKey: newData.paginationKey
          });
        }
      })
      .finally(() => setLoadingMore(false));
  };


  useEffect(() => {
    const maxPollingTime = 300000 * 2; // Maximum polling time of 10 mins
    const startTime = Date.now();
    let pollingInterval;
  
    const pollTask = async () => {
      if (pollingTaskId !== null && Date.now() - startTime < maxPollingTime) {
        try {
          const task = await fetchData({ info_type: 'task_poll', task_id: pollingTaskId });
          
          setThreadTaskStatuses((prevStatuses) => ({
            ...prevStatuses,
            [pollingTaskId]: { ...task['processing_result']},
          }));
          if (task === 'aborted') {
            setGeneratedOrder(null);
            setPollingTaskId(null);
          } else if (task.task.error) {
            console.error('Task error:', task.task.error);
            setGeneratedOrder({ error: task.task.error });
            setPollingTaskId(null);
          } else if (task.task.complete) {
            setGeneratedOrder(task.task.result);
            setPollingTaskId(null);
          } else {
            pollingInterval = setTimeout(pollTask, 1000);
          }
        } catch (error) {
          console.error('Error polling task:', error);
          setGeneratedOrder({ error });
          setPollingTaskId(null);
        }
      } else {
        setPollingTaskId(null);
      }
    };
  
    pollTask();
  
    return () => clearTimeout(pollingInterval);
  }, [pollingTaskId]);


  const renderActionsBar = () => {
    const dataClassification = (
        (threadMessages ?? [])
          .map((m) => m.attachments)
          .flat()
          .filter((a) => a?.data_classification) ?? [{ data_classification: 'OTHER' }]
      )[0]?.data_classification;
    
  
    const getTooltipText = (classification) => {
      switch (classification) {
        case 'CUSTOMER_DATA':
          return 'Reprocess customer import data to populate customer details';
        case 'SHIP_TO_DATA':
          return 'Reprocess ship to import data to populate ship to details';
        case 'PRODUCT_DATA':
          return 'Reprocess product import data to populate product details';
        case 'ORDER_DATA':
          return 'Reprocess order import for velocity reports, cross-selling, unified reporting, and more';
        case 'CUSTOMER_INVENTORY_DATA':
          return 'Reprocess customer inventory data to populate days on hand and velocity reports';
        default:
          return 'Reprocess the selected data based on its classification.';
      }
    };
  
    const currentTaskStatus = threadTaskStatuses[selectedThread?.ThreadId];

    return (
      <div>
        {selectedThread &&
          threadMessages !== null &&
          threadMessages.filter((m) => m.web_order).length === 0 && (
            <>
            <Row style={{ margin: '15px 0px 0px 15px' }}>
                  <Typography.Text>Data Classification</Typography.Text>
            </Row>
            <Row style={{ margin: '0 0px 0px 15px' }}>
              
              <Select
                style={{ width: 200 }}
                options={[
                  {
                    label: (
                      <Typography.Text>
                        <Sparkles /> Order Import
                      </Typography.Text>
                    ),
                    value: 'ORDER_DATA',
                  },
                  {
                    label: (
                      <Typography.Text>
                        <Sparkles /> Customer Import
                      </Typography.Text>
                    ),
                    value: 'CUSTOMER_DATA',
                  },
                  {
                    label: (
                      <Typography.Text>
                        <Sparkles /> Ship To Import
                      </Typography.Text>
                    ),
                    value: 'SHIP_TO_DATA',
                  },
                  {
                    label: (
                      <Typography.Text>
                        <Sparkles /> Product Import
                      </Typography.Text>
                    ),
                    value: 'PRODUCT_DATA',
                  },
                  {
                    label: (
                      <Typography.Text>
                        <Sparkles /> Customer Inventory
                      </Typography.Text>
                    ),
                    value: 'CUSTOMER_INVENTORY_DATA',
                  },
                  { label: <Typography.Text>Other</Typography.Text>, value: 'OTHER' },
                ]}
                value={dataClassification}
                onChange={(value) => {}}
              />
            </Row>
            </>
          )}
  
        {dataClassification &&
          dataClassification !== 'OTHER' &&
          selectedThread &&
          threadMessages !== null &&
          threadMessages.filter((m) => m.web_order).length === 0 && (
            <>
            <br></br>
            <br></br>
            {currentTaskStatus && currentTaskStatus.status === 'processing' &&(
                <Alert
                  message={`[${dayjs(currentTaskStatus.timestamp)}] Processing`}
                  description="Check back in a few minutes..."
                  type="info"
                  showIcon
                  style={{ margin: 15 }}
                />
              )}
              {currentTaskStatus && currentTaskStatus.status === 'complete' && !((currentTaskStatus.has_warning || currentTaskStatus.result.num_skipped > 0))  &&(
                <Alert
                  message={
                    `[${dayjs(currentTaskStatus?.timestamp)}] Processing Complete`
                  }
                  description={
                    `Data imported: ${volumeFormatter.format(currentTaskStatus?.result.num_total ?? 0)} rows`
                  }
                  type="success"
                  showIcon
                  style={{ margin: 15 }}
                />
              )}
              {currentTaskStatus && currentTaskStatus.status === 'complete' && (currentTaskStatus.has_warning || currentTaskStatus.result.num_skipped > 0) && (
                <Alert
                  message={
                    `[${dayjs(currentTaskStatus?.timestamp)}] Processing Complete`
                  }
                  description={
                    `Data imported: ${volumeFormatter.format((currentTaskStatus?.result.num_total ?? 0) - (currentTaskStatus?.result.num_skipped ?? 0))} rows (${volumeFormatter.format(currentTaskStatus?.result.num_skipped ?? 0)} rows skipped)`
                  }
                  type="warning"
                  showIcon
                  style={{ margin: 15 }}
                />
              )}
              {currentTaskStatus && currentTaskStatus.status === 'error' && (
                <Alert
                    message={`[${dayjs(currentTaskStatus?.timestamp)}] Task Failure`}
                    description={selectedThread.ThreadId}
                    type="error"
                    showIcon
                    style={{ margin: 15 }}
                />
              )}
            <Button
                    type="primary"
                    style={{ margin: 15 }}
                    onClick={() => startCreateOrderWorkflow()}
                >
                    <ReloadOutlined />Run Again
                </Button>
                <Typography.Text
                    type="secondary"
                    style={{ display: 'block', marginLeft: 15 }}
                >
                    <InfoCircleOutlined /> {getTooltipText(dataClassification)}
                </Typography.Text>
                </>
          )}
      </div>
    );
  };

  return (
    <>
      <ThreadsExplorerBase
        userData={userData}
        fetchData={fetchData}
        renderActionsBar={renderActionsBar}
        onMessageClick={handleMessageClick}
        onThreadSelect={onThreadSelect}
        pollingTaskId={pollingTaskId}
        setPollingTaskId={setPollingTaskId}
        generatedOrder={generatedOrder}
        setGeneratedOrder={setGeneratedOrder}
        selectedMessage={selectedMessage}
        setSelectedMessage={setSelectedMessage}
        selectedThread={selectedThread}
        setSelectedThread={setSelectedThread}
        dataSource={dataSource}
        setDataSource={setDataSource}
        showNewTasksOnly={showNewTasksOnly}
        setShowNewTasksOnly={setShowNewTasksOnly}
        threadMessages={threadMessages}
        setThreadMessages={setThreadMessages}
        loadingMore={loadingMore}
        setLoadingMore={setLoadingMore}
        loadMore={loadMore}
        products={products}
        setHasError={setHasError}
        showSuccessAlert={showSuccessAlert}
        showFailedAlert={showFailedAlert}
        largeAction={true}
        workflowComponent={
            <Workflow
                userData={userData}
                workflowState={workflowState}
                configType={workflowConfig}
                onFinish={handleWorkflowFinish}
                onCancel={handleWorkflowCancel}
                fullscreen={fullScreen}
                toggleFullScreen={toggleFullScreen}
            />
        }
        workflowVisible={workflowVisible}
        fullScreen={fullScreen}
      />
    </>
  );
};

export default IngestLog;