import React, { useState } from 'react';
import { LoadingOutlined, SearchOutlined} from '@ant-design/icons';
import {Col, Row, Select, Tag} from "antd";
import {FilterOutlined} from "@ant-design/icons";


const MultiSelect = (props) => {
    const {selectOptions, setSelected, selected, objectName, loading, style} = props;
    
    const [searchString, setSearchString] = useState('');
    const [isFocused, setIsFocused] = useState(false);

    const handleSelectAll = () => {
        const filteredIds = selectOptions
            .filter(option => 
                option.name.toLowerCase().includes(searchString.toLowerCase())
            )
            .map(option => option.id);
        setSelected([...new Set([...selected, ...filteredIds])]);
    };

    const dropdownRender = menu => (
        <div>
            <Row>
            {searchString && searchString.length > 1 &&
                <Col
                    style={{cursor: 'pointer' }}
                    onMouseDown={e => e.preventDefault()}  // prevent the click event from triggering the selection of options
                    onClick={handleSelectAll}
                >
                    <Tag style={{fontSize: 12}}>Select All ({
                        selectOptions.filter(option => option.name.toLowerCase().includes(searchString.toLowerCase())).length
                        }) in Search</Tag>
                </Col>
            }
            {selected.length > 0 &&
                <Col
                    style={{cursor: 'pointer' }}
                    onMouseDown={e => e.preventDefault()}  // prevent the click event from triggering the selection of options
                    onClick={handleDeselectAll}
                >
                    <Tag style={{fontSize: 12}}>Clear All ({selected.length})</Tag>
                </Col>
            }
            </Row>
            <div>{menu}</div>
        </div>
    );

    const handleDeselectAll = () => {
        setSelected([]);  // Clear the selected 
    };    

    return (
        <Select
            showSearch
            style={style ? style : {width: '100%', maxWidth: '280px'}}
            optionFilterProp="children"
            mode="multiple"
            value={isFocused ? selected : (selected.length === 0 ? `No ${objectName} Filters` : `Filtered ${objectName}(s) (${selected.length})`)}
            filterOption={(input, option) => {
                    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
                }
            }
            suffixIcon={loading ? <LoadingOutlined style={{color: 'black'}}/> : <FilterOutlined/>}
            onChange={(value, event) => {
                setSelected(value);
            }}
            options={selectOptions.map((c) => {
                return {label: c['name'], value: c['id'], disabled: c['disabled']}}).sort((c1, c2) => {
                    if (selected.includes(c1.value) && !selected.includes(c2.value)) return -1;
                    if (!selected.includes(c1.value) && selected.includes(c2.value)) return 1;
                    else return 0;
                })
            }
            tagRender={props => {
                const { label, closable, onClose } = props;

                return (
                    <Tag style={{display: (label.includes('Filter') || selected.length === 1) ? undefined : 'none'}}>
                        {label}
                        {closable && (
                            <span onClick={onClose} style={{ marginLeft: 8, cursor: 'pointer' }}>
                                x
                            </span>
                        )}
                    </Tag>
                );
            }}
            onBlur={() => {setIsFocused(false); setSearchString('');}}
            onFocus={() => setIsFocused(true)}
            onSearch={(value) => setSearchString(value)}
            dropdownRender={dropdownRender} 
        />
    );
}

export default MultiSelect;
