import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Auth0Provider} from '@auth0/auth0-react';
import LogoutButton from "./logoutButton";

const Auth0ProviderWithHistory = ({org_id, children}) => {
    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

    const nav = useNavigate();

    const onRedirectCallback = (appState) => {
        console.log(window.location.origin)
        //nav(window.location.pathname)
       nav(appState.returnTo);
    };

    const urlParams = new URLSearchParams(window.location.search);
    const error = urlParams.get('error'); // check for any auth0 errors (e.g. user tries to access an org they're not a member of)

    if (error !== null) {
        const error_desc = urlParams.get('error_description');

        // show a text only page with the error message and a log out button so users can try logging into the right portal / with the right user
        return (<div>Login failed. <br></br>{error !== null ? 'error: ' + error : ''} <br></br> {error_desc !== null ? 'error_description: ' + error_desc : ''}
        <br></br><br></br>
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            audience={audience}
        >
        <LogoutButton/>
        </Auth0Provider></div>);
    }

    // this is intentionally omitted so users that are part of several orgs get the org picker
    // for more, see https://auth0.com/docs/manage-users/organizations/using-tokens#authenticate-users-through-an-organization
    var local_org_id = undefined;
    if ((typeof org_id === 'string' || org_id instanceof String) && org_id.length > 0 && org_id !== 'undefined' && org_id !== 'null') {
        local_org_id = org_id;
    }

    const invitation = urlParams.get('invitation');
    const invitation_organization = urlParams.get('organization'); // is part of a generated invitation url
    var local_invitation = undefined;
    if (invitation !== null && invitation_organization !== null) {
        local_invitation = invitation;
        local_org_id = invitation_organization;
    }

    return (
        // users can provide organization ID for login as a query param:
        // e.g. http://localhost:8080/?portal=org_ybcN9mALqlsBSIz0 (demo account)
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            audience={audience}
            useRefreshTokens={true}
            organization={local_org_id}
            invitation={local_invitation}
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;