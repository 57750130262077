// ThreadsExplorerBase.js

import React, { useState, useEffect, useRef } from "react";
import { Layout, List, Typography, Col, Badge, Button, Switch, Spin, Row, Alert } from 'antd';
import { Link } from "react-router-dom";
import { WarningTwoTone, WarningOutlined } from '@ant-design/icons';
import dayjs from "dayjs";
import Loading from "../Loading";
import {EmailContent} from './EmailContent';

const { Sider, Content } = Layout;
const { Text } = Typography;

const ThreadsExplorerBase = (props) => {
  const {
    userData,
    fetchData,
    renderActionsBar,
    onMessageClick,
    onThreadSelect,
    pollingTaskId,
    setPollingTaskId,
    generatedOrder,
    setGeneratedOrder,
    selectedMessage,
    setSelectedMessage,
    selectedThread,
    setSelectedThread,
    dataSource,
    setDataSource,
    showNewTasksOnly,
    setShowNewTasksOnly,
    threadMessages,
    setThreadMessages,
    loadingMore,
    setLoadingMore,
    loadMore,
    products,
    setHasError,
    showSuccessAlert,
    showFailedAlert,
    workflowComponent,
    workflowVisible,    
    fullScreen,
    selectThread,      
    handleDismissClick, 
    largeAction  
  } = props;

  const [fromEmails, setFromEmails] = useState([]);
  const [forceThreadUpdate, setForceThreadUpdate] = useState(false);
  const abortControllerRef = useRef(null);

  useEffect(() => {
    if (dataSource === null) {
      fetchData({ info_type: 'threads' }).then(data => {
        setFromEmails(data?.emails);
        setDataSource({...data, threads: data?.threads.sort((a, b) => dayjs(b.Timestamp).diff(dayjs(a.Timestamp)))});
        
        if (data?.threads.length > 0) {
          selectThreadBase(data.threads[0], true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (selectedThread) {
      const threadOnSelection = selectedThread.ThreadId;


      fetchData({ info_type: 'specific_thread', ThreadId: threadOnSelection }).then(messages => {
        if (selectedThread.ThreadId === threadOnSelection && messages !== 'aborted') {
          const sortedMessages = messages['messages'].sort((a, b) => new Date(b.datetime) - new Date(a.datetime));
          setThreadMessages(sortedMessages);

          if (messages['action_task_id'] && !largeAction) // don't set polling task id if largeAction is true because that's IngestLog and we don't want to prefetch
            setPollingTaskId(messages['action_task_id']);
          else {
            setGeneratedOrder({ "DOCUMENT_TYPE_CLASSIFICATION": "OTHER" });
          }
        }
      });
    } else {
      setThreadMessages([]);
    }
  }, [selectedThread, forceThreadUpdate]);

  const selectThreadBase = (thread, clear) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    setSelectedMessage(null);
    setGeneratedOrder(null);
    setPollingTaskId(null);
    if (clear) setThreadMessages(null);
    setSelectedThread(thread);
    setForceThreadUpdate(!forceThreadUpdate);

    if (onThreadSelect) {
      onThreadSelect(thread);
    }
  };

  if (dataSource === null) return <Loading style={{ paddingTop: 200 }} />;

  return (
    <>
      {showSuccessAlert !== null && (
        <Alert
          message={<Link to={`/orders/order?order_id=${showSuccessAlert.order_id}`}>Order Saved</Link>}
          type="success"
          showIcon
          style={{
            position: 'absolute',
            bottom: 10,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
            width: '25%'
          }}
        />
      )}
      {showFailedAlert !== null && (
        <Alert
          message={showFailedAlert}
          type="error"
          showIcon
          closable
          style={{
            position: 'absolute',
            bottom: 10,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
            width: '35%'
          }}
        />
      )}
      <div style={{ position: 'relative' }}>
        <Layout style={{ height: 'calc(100vh - 58px)', display: 'flex' }}>
          {/* Sider for Thread List */}
          <Sider
            width={300}
            theme="light"
            style={{
              overflow: 'auto',
              maxHeight: '100vh',
              display: workflowVisible ? 'none' : 'block'
            }}
        >
            {/* Header */}
            <div style={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: '#fff',
              padding: '10px',
              borderBottom: '1px solid #f0f0f0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
            }}>
              { !largeAction && <>
                  {showNewTasksOnly ?
                    <Typography.Text strong>Show Only Incomplete Tasks</Typography.Text> :
                    <Typography.Text strong>Show Only Completed Tasks</Typography.Text>
                  }
                  <Switch checked={showNewTasksOnly} onChange={(checked) => {
                    setShowNewTasksOnly(checked);
                    setSelectedMessage(null);
                    setSelectedThread(null);
                    setDataSource(null);
                    fetchData({ info_type: 'threads', showDismissed: !checked }).then(data => {
                      setFromEmails(data['emails']);
                      setDataSource(data);
                      if (data.threads.length > 0) {
                        selectThreadBase(data.threads[0], true);
                      }
                    });
                  }} />
                </>
              }
            </div>
            {/* Thread List */}
            <div style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 58px)' }}>
              <List
                itemLayout="horizontal"
                dataSource={showNewTasksOnly ? dataSource.threads.filter((t) => t.isNew === "1") : dataSource.threads}
                renderItem={(thread) => (
                  <List.Item
                    key={`${thread.ThreadId}-${thread.isNew}`}
                    id={`thread-${thread.ThreadId}`}
                    onClick={() => selectThreadBase(thread, true)}
                    style={{
                      paddingLeft: '10px',
                      paddingRight: 5,
                      backgroundColor: selectedThread && selectedThread.ThreadId === thread.ThreadId ? '#e6f7ff' : 'transparent',
                      cursor: 'pointer'
                    }}
                  >
                    <List.Item.Meta
                      title={
                        <>
                          <Row>
                            <Col flex={'3px'}>
                              {thread.isNew === '1' && (
                                largeAction ? (
                                  thread.has_warning ? <WarningOutlined style={{marginRight: 3, color: '#FFD666'}}/> : undefined
                                ) : (
                                  <Badge dot style={{ marginRight: 8 }} />
                                )
                              )}
                            </Col>
                            <Col flex={'200px'} style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                              <Text strong>{thread.From ? (thread.From[0].name || thread.From[0].email) : ''}</Text>
                            </Col>
                            <Col flex='auto' />
                            <Col>
                              <Text style={{ fontSize: '12px', paddingRight: 5 }}>{dayjs(thread.Timestamp).format('MM/DD')}</Text>
                            </Col>
                          </Row>
                        </>
                      }
                      description={thread.Preview}
                    />
                  </List.Item>
                )}
                footer={
                  dataSource.paginationKey !== null && (
                    <div style={{ textAlign: 'center', margin: '10px 0' }}>
                      {loadingMore ? (
                        <Spin />
                      ) : (
                        <Button onClick={loadMore}>Load More</Button>
                      )}
                    </div>
                  )
                }
              />
            </div>
          </Sider>
          {/* Content */}
          {!fullScreen && (
            <Content style={{ flex: 1, overflow: 'auto' }}>
              {selectedThread ? (
                <div>
                  {threadMessages && threadMessages.length > 0 ? threadMessages.map((message, index) => (
                    <EmailContent
                      key={index}
                      message={message}
                      onClick={onMessageClick}
                      selectedEntity={selectedMessage}
                      lastDismissed={selectedThread.lastDismissed}
                    />
                  )) : <Loading style={{ paddingTop: 200 }} />}
                </div>
              ) : (
                <Row>
                  <Col flex={'auto'}></Col>
                  <Col style={{ paddingTop: 100 }}>
                    {!largeAction && <Text>No tasks to review! 🎉</Text>}
                    {largeAction && <Text>No events to import!</Text>}
                  </Col>
                  <Col flex='auto'></Col>
                </Row>
              )}
            </Content>
          )}
          {/* Actions Bar and Workflow */}
          <Sider
            width={fullScreen ? '100%' : (workflowVisible ? 600 : (largeAction ? 260 : 200))}
            theme="light"
          >
            <div style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 58px)' }}>
              {workflowVisible ? (
                workflowComponent
              ) : (
                renderActionsBar && renderActionsBar()
              )}
            </div>
          </Sider>
        </Layout>
      </div>
    </>
  );
};

export default ThreadsExplorerBase;