const serverUrl = process.env.REACT_APP_API_URL;


const fetchOpenOrderData = async (accessToken, props, options = {}) => {
    // send customerIDs in body to avoid potential http 414 on too many query params if many are selected
    let body = props;
    let headers = {
        Authorization: `Bearer ${accessToken}`,
    }

    const response = await fetch(
        `${serverUrl}/open_orders?` + new URLSearchParams({
            onlyOpen: props.onlyOpen !== undefined ? props.onlyOpen: true,
            imported: props.imported !== undefined ? props.imported: false,
            since: props.since ? props.since: null
        }),
        {
            headers: headers,
            body: JSON.stringify(body),
            method: "POST",
            ...options
        },
    );

    if (response.status === 403) {
        throw new Error('Not Found');
    }

    return await response.json();
};

export {fetchOpenOrderData};
