import React, { useState, useRef } from "react";
import { AutoComplete, Input, Spin, Typography } from "antd";
import { Link } from "react-router-dom";
import { fetchThreadsData } from "../../endpoints/fetchThreadsData";
import { LoadingOutlined } from "@ant-design/icons";

const { Text } = Typography;

const OrderSearch = ({ userData, navLinks, onSelect }) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const abortControllerRef = useRef(null);

    const fetchData = async (value) => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();

        setLoading(true);
        setOptions([]);

        try {
            const results = await fetchThreadsData(
                userData.accessToken,
                { info_type: 'search_order', text: value },
                { signal: abortControllerRef.current.signal }
            );

            const formattedOptions = results['results'].map((item) => ({
                value: item.name,
                id: item.id,
                label: (
                    <div key={item.id}>
                        {navLinks ? <Link to={`/orders/order/?order_id=${item.id}`}>{item.name}</Link> : <Text>{item.name}</Text>}
                    </div>
                ),
            }));

            setOptions(formattedOptions.length ? formattedOptions : [{ value: 'no-results', label: 'No results found', disabled: true }]);
            setLoading(false);
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Fetch aborted');
            } else {
                console.error("Failed to fetch search results:", error);
                // Display an error message in the dropdown
                setOptions([{ value: 'error', label: (<div>Error fetching results. Please try again.</div>), disabled: true }]);
                setLoading(false);
            }
        }
    };

    const handleSearchTrigger = (value) => {
        const val = value.target ? value.target.value : value;
        fetchData(val.trim());
    };

    return (
        <AutoComplete
            style={{ width: '100%' }}
            options={options}
            notFoundContent={loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : null}
        >
            <Input.Search
                placeholder="Search orders..."
                enterButton
                size="medium"
                loading={loading}
                onPressEnter={handleSearchTrigger}
                onSearch={handleSearchTrigger}
                onSelect={(value) => onSelect !== undefined ? onSelect(value, options.filter((o) => o.value === value.target.value)) : null}
            />
        </AutoComplete>
    );
};

export default OrderSearch;
