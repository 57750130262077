import {Layout, Menu, Button, Row, Badge, Typography} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {Content} from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import {
    SettingOutlined,
    ShoppingCartOutlined,
    CompassOutlined,
    MailOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    SmileOutlined,
    DollarOutlined, LineChartOutlined
} from "@ant-design/icons";
import Loading from "../Loading";
import moment from "moment";
import { fetchThreadsData } from "../../endpoints/fetchThreadsData";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import { write } from "xlsx";

const sideColor = '#F5F5F5'

const OrderContainer = (props) => {
    const {userData, hasError, setHasError} = props;

    const nav = useNavigate();
    const abortControllerRef = useRef(null);

    const [pageMenu, setPageMenu] = useState('order');
    const [forReviewCount, setForReviewCount] = useState(null);
    const [products, setProducts] = useState([]);
    const [orderUnit, setOrderUnit] = useState(null);
    const [custConfigs, setCustConfigs] = useState(null);
    const [writeIntegration, setWriteIntegration] = useState(null);

    const {pathname} = useLocation();

    // Set nav state if relevant
    if (pathname.includes('open-orders') && pageMenu !== 'order') {
        setPageMenu('order');
    } else if (pathname.includes('threads') && pageMenu !== 'review') {
        setPageMenu('review');
    } else if (pathname.includes('customer') && pageMenu !== 'customers') {
        setPageMenu('customers');
    }

    const [collapsed, setCollapsed] = useState(false); 
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const fetch = async () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        // Create a new AbortController
        abortControllerRef.current = new AbortController();

        const result = await fetchThreadsData(
            userData.accessToken,
            {'info_type': 'undismissed_count'}, 
            { signal: abortControllerRef.current.signal }
        )
        
        setCustConfigs(result['cust_configs']);
        setProducts(result['products']);
        setWriteIntegration(result['write_integration']);
        setForReviewCount(result['count']);
        setOrderUnit(result['default_po_unit']);
    }

    useEffect(() => {
        if (forReviewCount === null)
            fetch();
    }, []);
    
    return (
        <Layout>
            <Sider
                className={"side-menu"}
                style={{background: sideColor}}
                trigger={null} 
                width={150}
                collapsible
                collapsed={collapsed}
                onCollapse={toggleCollapsed}
                collapsedWidth={55}
            >
                <Menu
                    style={{background: sideColor}}
                    mode="inline"
                    selectedKeys={[pageMenu]}
                    onSelect={({key, keyPath, selectedKeys, domEvent}) => {
                        setPageMenu(key);
                        
                        // Nav if relevant
                        if (key === 'order') {
                            nav('/orders/open-orders');
                        } else if (key === 'review') {
                            nav('/orders/threads');
                        } else if (key === 'customers') {
                            nav('/orders/customers');
                        }
                    }}
                    items={[
                        {
                            key: 'order',
                            icon: <ShoppingCartOutlined />,
                            label: <Link to="/orders/open-orders">Orders</Link>,
                        },
                        {
                            key: 'review',
                            icon: <MailOutlined />,
                            label: <Link to="/orders/threads">Review <Badge count={forReviewCount} /></Link>,
                        },
                        {
                            key: 'customers',
                            icon: <SmileOutlined />,
                            label: <Link to="/orders/customers">Customers</Link>,
                        },
                    ]}
                />
                <Row className="sider-collapse-button" style={{justifyContent: 'right'}}>
                    <Button onClick={toggleCollapsed} style={{ marginBottom: 16 }}>
                        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
                    </Button>
                </Row>  
            </Sider>
            <Content>
                <div className="threads">
                    <Outlet context={[forReviewCount, setForReviewCount, products, setProducts, orderUnit, setOrderUnit, custConfigs, setCustConfigs, writeIntegration]}/>
                </div>
            </Content>
        </Layout>
    );
}

export default withAuthenticationRequired(OrderContainer, {
    onRedirecting: () => <Loading/>,
});
