// src/components/logoutButton.js

import React from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {Button} from "antd";

const LogoutButton = () => {
    const {logout} = useAuth0();
    return (
        <Button
            type="primary"
            className="btn btn-danger btn-block"
            onClick={() => {
                    // persist in local storage so that hard refreshes won't force users to pick their org again
                    // we clear local storage when they log out so they can pick their desired org then
                    window.localStorage.removeItem("pantry-ai-current-org-id");
                    logout({
                        returnTo: window.location.origin,
                    })
                }
            }
        >
            Log Out
        </Button>
    );
};

export default LogoutButton;