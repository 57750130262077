// CustomerReport.js
import {
  Card,
  Row,
  Col,
  Statistic,
  DatePicker,
  Typography,
  Table,
  Skeleton,
  Tooltip as AntTooltip,
  Modal,
} from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { fetchThreadsData } from '../../endpoints/fetchThreadsData';
import { currencyFormatter, shortCurrencyFormatter } from '../utils';
import Loading from '../Loading';
import { useSearchParams, useOutletContext, Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import MultiSelect from '../ui/MultiSelect';
import { Pie } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleOrdinal, scaleTime, scaleLinear } from '@visx/scale';
import { arc, LinePath, Circle } from '@visx/shape';
import { extent, bisector } from 'd3-array';
import { localPoint } from '@visx/event';
import { useTooltip, TooltipWithBounds } from '@visx/tooltip';
import { timeFormat } from 'd3-time-format';
import { curveMonotoneX } from '@visx/curve';
import { AxisBottom, AxisLeft } from '@visx/axis'; // Corrected import

const { RangePicker } = DatePicker;

// Define the primary color
const colorPrimary = '#3f8a78';

// Function to generate lighter shades of the primary color based on original lightness
const generateLighterShades = (baseColor, numberOfShades) => {
  const shades = [];
  // Convert hex to RGB
  const hex = baseColor.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  // Convert RGB to HSL
  const { h, s, l: originalL } = rgbToHsl(r, g, b);
  // Define step based on original lightness to ensure smaller increments
  const step = (1 - originalL) / (numberOfShades + 1);
  for (let i = 1; i <= numberOfShades; i++) {
    const lightness = Math.min(1, originalL + step * i);
    const newColor = hslToHex(h, s, lightness);
    shades.push(newColor);
  }
  return shades;
};

// Helper functions to convert RGB to HSL and HSL to Hex
const rgbToHsl = (r, g, b) => {
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = ((g - b) / d + (g < b ? 6 : 0)) * 60;
        break;
      case g:
        h = ((b - r) / d + 2) * 60;
        break;
      case b:
        h = ((r - g) / d + 4) * 60;
        break;
      default:
        h = 0;
    }
  }

  return { h, s, l };
};

const hslToHex = (h, s, l) => {
  l = Math.max(0, Math.min(1, l)); // Clamp l between 0 and 1
  s = Math.max(0, Math.min(1, s)); // Clamp s between 0 and 1
  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;
  let r, g, b;

  if (h >= 0 && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (h >= 60 && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (h >= 120 && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (h >= 180 && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (h >= 240 && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else {
    r = c;
    g = 0;
    b = x;
  }

  const toHex = (val) => {
    const hex = Math.round((val + m) * 255).toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

// Generate 10 lighter shades of the primary color
const lighterShades = generateLighterShades(colorPrimary, 10);

// Define color scales using the lighter shades and a grey for "Other"
const productColorScale = scaleOrdinal({
  domain: [],
  range: [...lighterShades, '#A9A9A9'], // Adding grey for "Other"
});

const shipToColorScale = scaleOrdinal({
  domain: [],
  range: [...lighterShades, '#A9A9A9'], // Adding grey for "Other"
});

// Define "Other" label
const OTHER_LABEL = 'Other';

// Reusable Donut and Table Component
const DonutAndTable = ({
  chartData,
  colorScale,
  title,
  tableColumns,
  tableData,
  handlePieClick,
  handleTableRowClick,
  selectedItem,
}) => {
  // Define Arc Generators with adjusted radii for a more pronounced donut
  const arcGenerator = arc()
    .innerRadius(70) // Increased innerRadius for a fuller donut
    .outerRadius(90);

  // Function to compute translation for selected slice
  const computeTranslation = (arc) => {
    // Use the arc generator's centroid method correctly
    const [centroidX, centroidY] = arcGenerator.centroid(arc);
    const angle = Math.atan2(centroidY, centroidX);
    const offset = 10; // Pixels to move outward
    const translateX = Math.cos(angle) * offset;
    const translateY = Math.sin(angle) * offset;
    return `translate(${translateX}, ${translateY})`;
  };

  return (
    <Row gutter={16} style={{ marginBottom: 16, width: '100%' }}>
      {/* Donut Chart */}
      <Col flex={'300px'}>
        <Card>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography.Title level={4}>{title} Distribution</Typography.Title>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 20,
              }}
            >
              <svg width={200} height={200}>
                <Group top={100} left={100}>
                  <Pie
                    data={chartData}
                    pieValue={(d) => d.value}
                    outerRadius={90}
                    innerRadius={70} // Increased innerRadius for fuller donut
                    padAngle={0.02}
                    cornerRadius={3}
                    colorScale={colorScale}
                    onClick={(event, d) => {
                      handlePieClick(d.data);
                    }}
                  >
                    {(pie) =>
                      pie.arcs.map((arc) => {
                        const path = arcGenerator(arc);
                        const isSelected =
                          selectedItem === arc.data.label ||
                          (selectedItem === OTHER_LABEL && arc.data.isOther);
                        const transform = isSelected ? computeTranslation(arc) : null;
                        return (
                          <g key={arc.data.label} transform={transform}>
                            <path
                              d={path}
                              fill={colorScale(arc.data.label)}
                              onClick={() => handlePieClick(arc.data)}
                              style={{
                                cursor: 'pointer',
                                transition: 'transform 0.3s, opacity 0.3s',
                                opacity: isSelected ? 0.7 : 1,
                              }}
                            />
                          </g>
                        );
                      })
                    }
                  </Pie>
                </Group>
              </svg>
            </div>
          </div>
        </Card>
      </Col>

      {/* Table */}
      <Col flex='auto' style={{ maxWidth: '70%' }}>
        <Card>
          <Typography.Title level={4}>{title} Breakdown</Typography.Title>
          <Table
            columns={tableColumns}
            dataSource={tableData}
            pagination={{ pageSize: 10 }}
            size='small'
            rowSelection={{
              type: 'radio',
              selectedRowKeys: selectedItem
                ? selectedItem === OTHER_LABEL
                  ? []
                  : tableData
                      .filter((item) => item.label === selectedItem)
                      .map((item) => item.key)
                : [],
              onChange: (selectedRowKeys, selectedRows) => {
                if (selectedRows.length > 0) {
                  const selected = selectedRows[0];
                  handleTableRowClick(selected);
                } else {
                  // Handle "Other" selection if needed
                  // For simplicity, deselecting here
                }
              },
            }}
          />
        </Card>
      </Col>
    </Row>
  );
};

const CustomerReport = (props) => {
  const { userData, setHasError } = props;
  const { accessToken } = userData;

  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [customerId, setCustomerId] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [ships, setShips] = useState([]);
  const [imported, setImported] = useState(null);
  const [orders, setOrders] = useState([]);
  const [selectedShipTos, setSelectedShipTos] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]); // New state for selected products
  const [pulling, setPulling] = useState(false);
  const [dateRange, setDateRange] = useState([
    dayjs().subtract(3, 'months'),
    dayjs(),
  ]);
  const [totalOrderValue, setTotalOrderValue] = useState(0);
  const [numOrders, setNumOrders] = useState(0);
  const [chartDataByShipTo, setChartDataByShipTo] = useState([]);
  const [chartDataByProduct, setChartDataByProduct] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null); // New state for selected product
  const [selectedShipTo, setSelectedShipTo] = useState(null); // State for selected Ship To

  // New states for table data
  const [allProductTableData, setAllProductTableData] = useState([]);
  const [allShipToTableData, setAllShipToTableData] = useState([]);

  // State for regression tooltip
  const [regressionTooltipData, setRegressionTooltipData] = useState(null);

  // Ref for aborting fetch requests if needed
  const abortControllerRef = useRef(null);

  const [
    forReviewCount,
    setForReviewCount,
    products,
    setProducts,
    orderUnit,
    setOrderUnit,
    custConfigs,
    setCustConfigs,
    writeIntegration,
  ] = useOutletContext();

  const navigate = useNavigate();

  // Get customer_id from URL param
  useEffect(() => {
    if (searchParams.has('customer_id')) {
      setCustomerId(searchParams.get('customer_id'));
    }
  }, [searchParams]);

  // Prepare ship options for MultiSelect
  const customerOptions = ships.map((ship) => ({
    id: ship['id'],
    name: ship['address'],
    label: ship['address'],
    archived: ship['archived'],
  }));

  // Prepare product options for MultiSelect
  const productOptions = products.map((product) => ({
    id: product.uuid,
    name: product.name,
    label: product.name,
  }));

  // Fetch Ship Tos and Orders based on customerId
  const handleGetShipTos = async (customerId) => {
    setLoading(true);
    setPulling(true);
    // Abort previous request if any
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    // Create a new AbortController
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    try {
      const response = await fetchThreadsData(
        accessToken,
        {
          info_type: 'get_ship_tos',
          customer_id: customerId,
          dateRange: dateRange
            .map((d) => d.toISOString().slice(0, 10))
            .join(','),
        },
        { signal: abortController.signal } // Pass the signal for aborting
      );

      // Update the state with the new data
      console.log(response);
      setShips(response['ship_tos']);
      setImported(response['imported']);
      setOrders(response['customer']['orders']);
      setCustomerName(response['customer']['name']);
    } catch (e) {
      if (e.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error(e);
        setHasError(e);
      }
    } finally {
      setLoading(false);
      setPulling(false);
    }
  };

  useEffect(() => {
    if (userData && customerId) {
      handleGetShipTos(customerId);
    }
    // Cleanup on unmount
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, userData, dateRange]);

  // Calculate total order value and prepare chart data whenever orders or filters change
  useEffect(() => {
    if (orders.length === 0) {
      setTotalOrderValue(0);
      setChartDataByShipTo([]);
      setChartDataByProduct([]);
      setAllShipToTableData([]);
      setAllProductTableData([]);
      return;
    }

    // Filter orders based on selected Ship Tos, selected Products, and date range
    const filteredOrders = orders.filter((order) => {
      const isSelectedShipTo =
        selectedShipTos.length === 0 ||
        selectedShipTos.includes(order['ship_to_id']);

      // If products are selected, check if the order contains any of the selected products
      const hasSelectedProduct =
        selectedProducts.length === 0 ||
        order['line_items'].some((item) =>
          selectedProducts.includes(item['association'])
        );

      return isSelectedShipTo && hasSelectedProduct;
    });

    // Calculate total order value
    const totalValue = filteredOrders.reduce((acc, order) => {
      const lineItems = order?.line_items || [];
      const orderTotal = lineItems.reduce((sum, item) => {
        return sum + item['PRODUCT_QUANTITY'] * item['PRODUCT_PRICE'];
      }, 0);
      return acc + orderTotal;
    }, 0);

    setTotalOrderValue(totalValue);
    setNumOrders(filteredOrders.length);

    // Prepare chart data by Ship To
    const shipToMap = {};
    filteredOrders.forEach((order) => {
      const shipToId = order['ship_to_id'];
      const shipToLabel =
        ships.find((ship) => ship.id === shipToId)?.address || 'Unknown';
      const lineItems = order?.line_items || [];
      const orderTotal = lineItems.reduce((sum, item) => {
        return sum + item['PRODUCT_QUANTITY'] * item['PRODUCT_PRICE'];
      }, 0);
      if (shipToMap[shipToLabel]) {
        shipToMap[shipToLabel] += orderTotal;
      } else {
        shipToMap[shipToLabel] = orderTotal;
      }
    });

    // Sort Ship Tos by value descending
    const sortedShipToData = Object.entries(shipToMap)
      .map(([label, value]) => ({ label, value }))
      .sort((a, b) => b.value - a.value);

    // Take top 10 Ship Tos
    const topShipTos = sortedShipToData.slice(0, 10);

    // Aggregate the rest into "Other"
    const otherShipToValue = sortedShipToData
      .slice(10)
      .reduce((acc, curr) => acc + curr.value, 0);

    const finalShipToData = topShipTos.map((item) => ({
      label: item.label,
      value: item.value,
      isOther: false,
    }));

    if (otherShipToValue > 0) {
      finalShipToData.push({
        label: OTHER_LABEL,
        value: otherShipToValue,
        isOther: true,
      });
    }

    setChartDataByShipTo(finalShipToData);

    // Prepare allShipToTableData
    const shipToTableData = ships.map((ship, index) => {
      const shipToLabel = ship.address || 'Unknown';
      const shipToValue = shipToMap[shipToLabel] || 0;
      return {
        key: index,
        label: shipToLabel,
        value: shipToValue,
        percent:
          totalOrderValue > 0 ? (shipToValue / totalOrderValue) * 100 : 0,
      };
    }).filter(
      (item) => item.value > 0
    );

    setAllShipToTableData(shipToTableData);

    // Prepare chart data by Product
    const productMap = {};
    filteredOrders.forEach((order) => {
      const lineItems = order?.line_items || [];
      lineItems.forEach((item) => {
        // If products are selected, only include those
        if (
          selectedProducts.length > 0 &&
          !selectedProducts.includes(item['association'])
        ) {
          return;
        }
        const productId = item['association'];
        const productName =
          products.find((product) => product.uuid === productId)?.name ||
          'Unknown';
        const itemTotal = item['PRODUCT_QUANTITY'] * item['PRODUCT_PRICE'];
        if (productMap[productName]) {
          productMap[productName] += itemTotal;
        } else {
          productMap[productName] = itemTotal;
        }
      });
    });

    // Convert productMap to array and sort by value descending
    const sortedProductData = Object.entries(productMap)
      .map(([name, value]) => ({ name, value }))
      .sort((a, b) => b.value - a.value);

    // Take top 10 products
    const topProducts = sortedProductData.slice(0, 10);
    // Aggregate the rest into "Other"
    const otherValue = sortedProductData
      .slice(10)
      .reduce((acc, curr) => acc + curr.value, 0);

    const productData = topProducts.map((item) => ({
      label: item.name,
      value: item.value,
      isOther: false,
    }));

    if (otherValue > 0) {
      productData.push({
        label: OTHER_LABEL,
        value: otherValue,
        isOther: true,
      });
    }

    setChartDataByProduct(productData);

    // Prepare allProductTableData
    const productTableData = products.map((product, index) => {
      const productTotal = productMap[product.name] || 0;
      return {
        key: index,
        label: product.name,
        value: productTotal,
        percent: totalOrderValue > 0 ? (productTotal / totalOrderValue) * 100 : 0,
      };
    }).filter(
      (item) => item.value > 0
    );

    setAllProductTableData(productTableData);
  }, [
    orders,
    selectedShipTos,
    selectedProducts,
    dateRange,
    ships,
    products,
    totalOrderValue,
  ]);

  // Update color scales after chart data is available
  useEffect(() => {
    if (chartDataByProduct.length > 0) {
      productColorScale.domain(chartDataByProduct.map((d) => d.label));
    }
    if (chartDataByShipTo.length > 0) {
      shipToColorScale.domain(chartDataByShipTo.map((d) => d.label));
    }
  }, [chartDataByProduct, chartDataByShipTo]);

  // Define Arc Generators with adjusted radii for a more pronounced donut
  const productArcGenerator = arc()
    .innerRadius(70) // Increased innerRadius for a fuller donut
    .outerRadius(90);

  // Handlers for selection
  const handleProductPieClick = (data) => {
    if (data.isOther) {
      setSelectedProduct(OTHER_LABEL);
    } else {
      setSelectedProduct(data.label);
    }
  };

  const handleProductTableRowClick = (record) => {
    const isTop10 = chartDataByProduct.some(
      (item) => item.label === record.label && !item.isOther
    );
    if (isTop10) {
      setSelectedProduct(record.label);
    } else {
      setSelectedProduct(OTHER_LABEL);
    }
  };

  const handleShipToPieClick = (data) => {
    if (data.isOther) {
      setSelectedShipTo(OTHER_LABEL);
    } else {
      setSelectedShipTo(data.label);
    }
  };

  const handleShipToTableRowClick = (record) => {
    const isTop10 = chartDataByShipTo.some(
      (item) => item.label === record.label && !item.isOther
    );
    if (isTop10) {
      setSelectedShipTo(record.label);
    } else {
      setSelectedShipTo(OTHER_LABEL);
    }
  };

  // Handler for date range change
  const handleDateRangeChange = (dates) => {
    if (dates) {
      console.log(dates);
      setDateRange(dates);
    } else {
      // Reset to last three months if no date selected
      setDateRange([dayjs().subtract(3, 'months'), dayjs()]);
    }
  };

  // Columns for the Product Table with added "Percent of Total"
  const productColumns = [
    {
      title: 'Product Name',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Amount Sold',
      dataIndex: 'value',
      key: 'value',
      sorter: (a, b) => a.value - b.value,
      defaultSortOrder: 'descend',
      render: (value) => `${currencyFormatter.format(value)}`,
    },
    {
      title: 'Percent of Total',
      dataIndex: 'percent',
      key: 'percent',
      sorter: (a, b) => a.percent - b.percent,
      render: (value) => `${value.toFixed(2)}%`,
    },
  ];

  // Columns for the Ship To Table with added "Percent of Total"
  const shipToColumns = [
    {
      title: 'Ship To',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Amount',
      dataIndex: 'value',
      key: 'value',
      sorter: (a, b) => a.value - b.value,
      defaultSortOrder: 'descend',
      render: (value) => `${currencyFormatter.format(value)}`,
    },
    {
      title: 'Percent of Total',
      dataIndex: 'percent',
      key: 'percent',
      sorter: (a, b) => a.percent - b.percent,
      render: (value) => `${value.toFixed(2)}%`,
    },
  ];

  const formattedDateRange = `${dayjs(dateRange[0]).format(
    'MMM DD YYYY'
  )} - ${dayjs(dateRange[1]).format('MMM DD YYYY')}`;

  // Data for the Product Table with calculated percent
  // Now using allProductTableData which includes all products
  const productTableData = allProductTableData;

  // Data for the Ship To Table with calculated percent
  // Now using allShipToTableData which includes all ship tos
  const shipToTableData = allShipToTableData;

  // Determine selected row keys based on selectedProduct
  const selectedProductRowKeys = selectedProduct
    ? selectedProduct === OTHER_LABEL
      ? []
      : productTableData
          .filter((item) => item.label === selectedProduct)
          .map((item) => item.key)
    : [];

  // Determine selected row keys based on selectedShipTo
  const selectedShipToRowKeys = selectedShipTo
    ? selectedShipTo === OTHER_LABEL
      ? []
      : shipToTableData
          .filter((item) => item.label === selectedShipTo)
          .map((item) => item.key)
    : [];

  // Tooltip setup for Scatter Plot
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    showTooltip,
    hideTooltip,
  } = useTooltip();

  // Ref for the Scatter Plot container to determine its width
  const scatterRef = useRef(null);
  const [scatterWidth, setScatterWidth] = useState(window.innerWidth);
  const [scatterHeight, setScatterHeight] = useState(400);

  // dynamic resize causes performance issues
  // // Update scatter plot dimensions based on container size
  // useEffect(() => {
  //   const updateDimensions = () => {
  //     if (scatterRef.current) {
  //       const { width } = scatterRef.current.getBoundingClientRect();
  //       setScatterWidth(width);
  //       // Optionally, adjust height based on width to maintain aspect ratio
  //       setScatterHeight(400); // For 600x400 ratio
  //     }
  //   };

  //   updateDimensions();

  //   window.addEventListener('resize', updateDimensions);
  //   return () => window.removeEventListener('resize', updateDimensions);
  // }, []);

  // Prepare data for Scatter Plot
  const scatterData = orders
    .filter((order) => {
      const isSelectedShipTo =
        selectedShipTos.length === 0 ||
        selectedShipTos.includes(order['ship_to_id']);

      // If products are selected, check if the order contains any of the selected products
      const hasSelectedProduct =
        selectedProducts.length === 0 ||
        order['line_items'].some((item) =>
          selectedProducts.includes(item['association'])
        );

      return isSelectedShipTo && hasSelectedProduct;
    })
    .map((order) => {
      const date = dayjs(order['created_at']).toDate();
      const orderTotal = order['line_items'].reduce((sum, item) => {
        return sum + item['PRODUCT_QUANTITY'] * item['PRODUCT_PRICE'];
      }, 0);
      return { date, orderTotal, order };
    })
    .sort((a, b) => a.date - b.date); // Ensure data is sorted by date

  // Implement Linear Regression for Trend Line
  const computeLinearRegression = (data) => {
    const n = data.length;
    if (n === 0) return null;

    const sumX = data.reduce((acc, d) => acc + d.date.getTime(), 0);
    const sumY = data.reduce((acc, d) => acc + d.orderTotal, 0);
    const sumXY = data.reduce((acc, d) => acc + d.date.getTime() * d.orderTotal, 0);
    const sumX2 = data.reduce((acc, d) => acc + d.date.getTime() * d.date.getTime(), 0);

    const denominator = n * sumX2 - sumX * sumX;
    if (denominator === 0) return null;

    const slope = (n * sumXY - sumX * sumY) / denominator;
    const intercept = (sumY - slope * sumX) / n;

    return { slope, intercept };
  };

  const regression = computeLinearRegression(scatterData);


  const xDomain = extent(scatterData, (d) => d.date);

  // Define scales
  const xScale = scaleTime({
    domain: xDomain,
    range: [60, scatterWidth - 40], // Adjusted for margins
  });

  const yScale = scaleLinear({
    domain: [0, Math.max(...scatterData.map((d) => d.orderTotal), 0) * 1.1],
    range: [scatterHeight - 60, 40], // Adjusted for margins
    nice: true,
  });


  if (!orders) return <Loading style={{ paddingTop: 200 }} />;

  return (
    <div
      style={{
        padding: 15,
        maxHeight: 'calc(100vh - 58px)',
        overflowY: 'auto',
      }}
    >
      <Row gutter={16} style={{ marginBottom: 16 }}>
        {/* Customer Info Card */}
        <Col xs={24} sm={12} md={8}>
          <Card hoverable>
            {loading ? (
              <Skeleton active />
            ) : (
              <Statistic title='Customer' value={customerName || 'N/A'} />
            )}
          </Card>
        </Col>

        <Col xs={24} sm={12} md={8}>
          <Card hoverable>
            {loading ? (
              <Skeleton active />
            ) : (
              <Statistic
                title={`${formattedDateRange}`}
                value={totalOrderValue}
                precision={2}
                valueStyle={{ color: '#3f8600' }}
                prefix='$'
              />
            )}
          </Card>
        </Col>

        <Col xs={24} sm={24} md={8}>
          <Card hoverable>
            {loading ? (
              <Skeleton active />
            ) : (
              <Statistic
                title={`${formattedDateRange}`}
                value={numOrders}
                precision={0}
                suffix={'Orders'}
              />
            )}
          </Card>
        </Col>
      </Row>

      {/* Ship To and Product Selection */}
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col flex={'300px'}>
          <RangePicker
            value={dateRange}
            onChange={handleDateRangeChange}
            style={{ width: '100%' }}
            allowClear={false}
          />
        </Col>
        <Col flex={'300px'}>
          <MultiSelect
            selectOptions={customerOptions}
            setSelected={setSelectedShipTos}
            selected={selectedShipTos}
            objectName='Ship To'
            loading={pulling}
          />
        </Col>
        <Col flex={'300px'}>
          <MultiSelect
            selectOptions={productOptions}
            setSelected={setSelectedProducts}
            selected={selectedProducts}
            objectName='Product'
            loading={pulling}
          />
        </Col>
        <Col flex={'auto'}></Col>
      </Row>

      {/* Scatter Plot */}
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={24}>
          <Card style={{ position: 'relative' }} ref={scatterRef}>
            <svg width='100%' height={scatterHeight}>
              {/* X Axis */}
              <Group>
                <AxisBottom
                  top={scatterHeight - 60}
                  scale={xScale}
                  numTicks={6}
                  tickFormat={timeFormat('%b %d')}
                />
                <text
                  x={scatterWidth / 2}
                  y={scatterHeight - 20}
                  textAnchor='middle'
                  fontSize={12}
                >
                  Date
                </text>
              </Group>

              {/* Y Axis */}
              <Group>
                <AxisLeft
                  left={60}
                  scale={yScale}
                  numTicks={6}
                  tickFormat={(d) => shortCurrencyFormatter.format(d)}
                />
                <text
                  transform={`translate(10, ${
                    scatterHeight / 2
                  }) rotate(-90)`}
                  textAnchor='middle'
                  fontSize={12}
                >
                  Order Total
                </text>
              </Group>

              {/* Scatter Points with Enhanced Clickability */}
              {scatterData.map((d, i) => (
                <Group key={i}>
                  {/* Visible smaller circle */}
                  <Circle
                    cx={xScale(d.date)}
                    cy={yScale(d.orderTotal)}
                    r={4}
                    fill={colorPrimary}
                    fillOpacity={0.6} // Increased opacity for better visibility
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      const orderId = d.order.OrderId;
                      if (orderId) {
                        navigate(`/orders/order?order_id=${orderId}&imported=${imported}`);
                      }
                    }}
                  />
                </Group>
              ))}

              {/* Linear Regression Trend Line with Enhanced Clickability */}
              {regression && (
                <>
                  {/* Invisible thicker trend line */}
                  <LinePath
                    data={[
                      {
                        date: scatterData[0].date,
                        orderTotal:
                          regression.slope * scatterData[0].date.getTime() +
                          regression.intercept,
                      },
                      {
                        date: scatterData[scatterData.length - 1].date,
                        orderTotal:
                          regression.slope *
                            scatterData[scatterData.length - 1].date.getTime() +
                          regression.intercept,
                      },
                    ]}
                    x={(d) => xScale(d.date)}
                    y={(d) => yScale(d.orderTotal)}
                    stroke="transparent" // Invisible stroke
                    strokeWidth={10} // Increased width for clickable area
                    style={{ cursor: 'pointer' }}
                    onClick={(event) => {
                      const coords = localPoint(event);
                      if (coords) {
                        if (regressionTooltipData) {
                          setRegressionTooltipData(null); // Toggle off if already visible
                        } else {
                          setRegressionTooltipData({
                            left: coords.x,
                            top: coords.y,
                            slope: regression.slope,
                            intercept: regression.intercept,
                          });
                        }
                      }
                    }}
                  />
                  {/* Visible trend line */}
                  <LinePath
                    data={[
                      {
                        date: scatterData[0].date,
                        orderTotal:
                          regression.slope * scatterData[0].date.getTime() +
                          regression.intercept,
                      },
                      {
                        date: scatterData[scatterData.length - 1].date,
                        orderTotal:
                          regression.slope *
                            scatterData[scatterData.length - 1].date.getTime() +
                          regression.intercept,
                      },
                    ]}
                    x={(d) => xScale(d.date)}
                    y={(d) => yScale(d.orderTotal)}
                    stroke={'#ff7f0e'}
                    strokeWidth={2}
                  />
                </>
              )}
            </svg>
          </Card>
        </Col>
      </Row>

      {/* Ship To Donut and Table */}
      <DonutAndTable
        chartData={chartDataByShipTo}
        colorScale={shipToColorScale}
        title='Ship To'
        tableColumns={shipToColumns}
        tableData={shipToTableData}
        handlePieClick={handleShipToPieClick}
        handleTableRowClick={handleShipToTableRowClick}
        selectedItem={selectedShipTo}
      />

      {/* Products Donut and Table */}
      <DonutAndTable
        chartData={chartDataByProduct}
        colorScale={productColorScale}
        title='Product'
        tableColumns={productColumns}
        tableData={productTableData}
        handlePieClick={handleProductPieClick}
        handleTableRowClick={handleProductTableRowClick}
        selectedItem={selectedProduct}
      />
    </div>
  );
};

export default CustomerReport;