import React, { useState, useEffect } from 'react';
import { Progress } from 'antd';
import logo from "../logo.svg";

const Loading = ({ time, style = null}) => {
    const [percent, setPercent] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setPercent((prevPercent) => {
                if (prevPercent >= 100) {
                    clearInterval(interval);
                    return 100;
                }
                return prevPercent + (100 / time) * (10 / 1000);
            });
        }, 10);

        return () => clearInterval(interval);
    }, [time]);

    // Enhanced style for better alignment
    const containerStyle = {
        textAlign: 'center',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        ...style
    };

    return (
        <div style={containerStyle}>
            <div className="loading-container" style={{ marginBottom: 10 }}>
                <img src={logo} alt="Logo" />
            </div>
            <Progress style={{ width: '10%', marginLeft: 10}} type="line" percent={percent} showInfo={false} />
        </div>
    );
};

Loading.defaultProps = {
    time: 3, // Default time is 3 seconds
};

export default Loading;