import {Button, Col, Layout, Popover, Row, Steps, Typography} from "antd";
import logo from "../../logo.svg";
import AuthNav from "../../auth/authNav";
import {BugOutlined} from "@ant-design/icons";
import {Content, Footer} from "antd/es/layout/layout";
import React, {useState} from "react";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../Loading";
import {useNavigate} from "react-router-dom";

const menuColor = '#010F3C'
const contentColor = '#EDF0F8'
const colorPrimary = '#4fad96'

const Error500 = (props) => {
    const {userData} = props;
    const nav = useNavigate();

    return (
        <Layout className="layout">
            <Row className={'nav'} wrap={false}>
                <Col flex="auto" style={{background: menuColor}}>
                </Col>
                <Col flex="120px" style={{background: menuColor}}>
                    <AuthNav userData={userData}/>
                </Col>
            </Row>
            <Content style={{background: menuColor, paddingTop: 100, height: '100vh'}}>
                <Row align="center">
                    <BugOutlined style={{ fontSize: '70px', color: contentColor}}/>
                </Row>
                <br/>
                <br/>
                <br/>
                <Row align="center">
                    <Typography.Title style={{fontSize: 18, color: 'white'}}>Something went wrong! Please try reloading the page</Typography.Title>
                </Row>
                <Row align="center">
                    <Typography.Title style={{fontSize: 18, color: 'white'}}>We are building, learning, and updating quickly - if something is still wrong after reload contact us and we'll get right on it</Typography.Title>
                </Row>
                <Row align="center">
                    <Typography.Title style={{fontSize: 18, color: 'white'}}>ian@pantrysoftware.com</Typography.Title>
                </Row>
                <br/>
                <Row align="center">
                    <Button
                        size={"large"}
                        style={{background: colorPrimary, color: "white"}}
                        onClick={() => window.location.reload()}
                    >Reload</Button>
                </Row>
            </Content>
            <Footer style={{textAlign: 'center'}}>Pantry Software ©2023</Footer>
        </Layout>
    );
}

export default Error500;
