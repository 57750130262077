import {convertDayjsToStrings} from '../components/utils';

const serverUrl = process.env.REACT_APP_API_URL;


const fetchThreadsData = async (accessToken, body, options = {}) => {
    // send customerIDs in body to avoid potential http 414 on too many query params if many are selected
    let headers = {
        Authorization: `Bearer ${accessToken}`,
    }

    const response = await fetch(
        `${serverUrl}/threads?` + new URLSearchParams({
            foo: 'bar',
        }),
        {
            headers: headers,
            body: JSON.stringify(convertDayjsToStrings(body)),
            method: "POST",
            ...options
        },
    );

    if (response.status === 403) {
        throw new Error('Not Found');
    }

    return await response.json();
};

export {fetchThreadsData};
