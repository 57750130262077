class Address {
    // this is a clone of the pydantic-defined Address class in core/ship_to.py

    constructor(line1, line2, state, city, postalCode, country) {
      this.line1 = line1;
      this.line2 = line2;
      this.state = state;
      this.city = city;
      this.postalCode = postalCode;
      this.country = country;
    }
  
    toString() {
      return [this.line1, this.line2, this.city, this.state, this.postalCode, this.country]
        .filter(Boolean)
        .join(', ');
    }
  
    serialize() {
      return {
        LINE_1: this.line1,
        LINE_2: this.line2,
        CITY: this.city,
        STATE: this.state,
        POSTAL_CODE: this.postalCode,
        COUNTRY: this.country
      };
    }
  
    static deserialize(data) {
      return new Address(
        data.LINE_1,
        data.LINE_2,
        data.CITY,
        data.STATE,
        data.POSTAL_CODE,
        data.COUNTRY
      );
    }
  }

  export default Address;