import {
    LoadingOutlined,
    DollarCircleOutlined,
} from "@ant-design/icons";
import React, {useContext, useEffect, useRef} from "react";
import {Link, Outlet, useNavigate, useLocation} from 'react-router-dom'; 
import Loading from "../Loading";


const Dashboard = (props) => {
    const {userData} = props;
    const nav = useNavigate();
    const {pathname} = useLocation();

    useEffect(() => {
        if (userData == null) return;

        if (pathname === '/') {
            nav('orders/open-orders')
        }
    }, [userData]);

    return <Loading/>
}

export default Dashboard;