import React, {useState, useEffect} from "react";
import {Card, Col, Input, Row, Typography, Tag, Button, Skeleton} from "antd";
import {LoadingOutlined, PlusCircleOutlined, ArrowLeftOutlined, MailOutlined, ToolOutlined} from "@ant-design/icons";
import {BrowserRouter, Route, Routes, useNavigate} from 'react-router-dom';
import {getSettings} from "../../endpoints/settings";

const colorPrimary = '#4fad96';

const Integrations = (props) => {
    const {userData, setHasError} = props;
    const [search, setSearch] = useState('');
    const [selectedIntegration, setSelectedIntegration] = useState(null);
    const [showNewIntegration, setShowNewIntegration] = useState(false);
    const [dataSource, setDataSource] = useState(null);
    const [validating, setValidating] = useState(false);

    const handleGetSettings = async (to_save) => {
        if (!validating) {
            setValidating(true);
        } else {
            return;
        }

        let data;
        try {
            data = await getSettings(
                userData.accessToken,
                to_save
            );
        } catch (e) {
            setHasError(true);
            data = null;
        }

        if (data) {
            setDataSource(data);
            console.log(data)
        } else {
            // prevent infinite loop of attempted fetch if broken backend or network
            setDataSource([]);
            console.log('failed fetch')
        }

        setValidating(false);
        return data;
    }

    useEffect(() => {
        (async () => {
            // Initial fetch
            if (dataSource === null && !validating) {
                await handleGetSettings();
            }
        })();
    });

    const handleSearch = (e) => {
        setSearch(e.target.value);
    };

    let availableIntegrations = dataSource?.available_integrations || [];
    let existingIntegrations = dataSource?.existing_integrations || [];
    const allAvailableIntegrations = [...availableIntegrations]; // create a copy to prevent search affecting existing integrations' icon

    const handleBackClick = () => {
        setShowNewIntegration(false);
    };

    useEffect(() => {
        if (!dataSource) return;
        if (!dataSource['connect_card']) return;

        console.log(dataSource['connect_card'])
        window.open(dataSource['connect_card']['card'], '_blank').focus();
    }, [dataSource]);

    useEffect(() => {
        handleGetSettings(selectedIntegration ? [selectedIntegration] : undefined);
    }, [selectedIntegration]);

    const handleCardClick = (integration) => {
        if (integration.name !== 'Email')
            setSelectedIntegration(integration);
        else
            window.open(dataSource['nylas_auth'], '_blank').focus();
    };

    const handleNewIntegrationClick = () => {
        setShowNewIntegration(true);
    }

    if (search) {
        availableIntegrations = availableIntegrations.filter(integration =>
            integration.name.toLowerCase().includes(search.toLowerCase())
        );
    }

    return (
        <div style={{'paddingLeft': 15}}>
            <Row flex={'200px'}>
                <Col>
                    <Typography.Title level={4}>Connected Integrations</Typography.Title>
                </Col>
                <Col flex={'auto'}>
                </Col>
            </Row>
            { !showNewIntegration &&
                <>
                <Row gutter={[16, 16]}>
                    {existingIntegrations.map(integration => {
                        return (
                            <Col xs={24} sm={12} md={8} lg={6} key={integration.id}>
                                <div style={{ cursor: 'pointer' }}>
                                    <Card
                                        onClick={() => handleCardClick(integration)}
                                        title={
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                {integration.type}
                                            </div>
                                        }
                                        cover={
                                            integration.platform === 'Custom Read Integration' ? <ToolOutlined style={{fontSize: 100, paddingTop: 20}} />  : (
                                                integration.id === 'Email' ? 
                                                    <MailOutlined style={{fontSize: 100, paddingTop: 20}}/> : 
                                                    (
                                                        <img 
                                                            alt={integration.platform} 
                                                            src={integration.icon_url} 
                                                            style={{height: '120px', objectFit: 'contain', margin: '10px auto'}}
                                                        />
                                                    )
                                                )
                                        }
                                    >
                                        <Card.Meta
                                            description={
                                                <div>
                                                    <Row>
                                                        <Tag color={integration.status === "connected" ? "green" : "yellow"}>
                                                                {integration.status === "connected"
                                                                    ? "Connected"
                                                                    : integration.status === "incomplete"
                                                                        ? "Complete Setup"
                                                                        : integration.status
                                                                }
                                                        </Tag>
                                                        <Typography.Text>{integration.platform}</Typography.Text>
                                                    </Row>
                                                    {integration.status !== "incomplete" &&
                                                        <Row>
                                                            <Typography.Text>Last Sync: {integration.last_sync && integration.last_sync != 'N/A' ? new Date(integration.last_sync).toLocaleString() : 'N/A' }</Typography.Text>
                                                        </Row>
                                                    }
                                                </div>
                                            }
                                        />
                                    </Card>
                                </div>
                            </Col>
                        )
                    })}
                    {validating &&
                        <>
                            <Skeleton active/>
                        </>
                    }
                    {!validating && !showNewIntegration &&
                        <>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <div style={{ cursor: 'pointer' }}>
                            <Card
                                onClick={handleNewIntegrationClick}
                                title="Connect New Integration"
                                cover={<PlusCircleOutlined style={{fontSize: '100px', color: 'lightgrey', paddingTop: 30}} />}
                            >
                            </Card>
                            </div>
                        </Col>
                        </>
                    }
                </Row>
                </>
            }

            {showNewIntegration &&
                <>
                    <Row>
                        <Button icon={<ArrowLeftOutlined />} onClick={handleBackClick}>
                            Back to Integrations
                        </Button>
                    </Row>
                    <Typography.Title level={4}>Connect New Integration</Typography.Title>
                    <Row>
                        <Input.Search placeholder="Search integrations..." onChange={handleSearch} style={{ marginBottom: '1em', marginTop: '1em' }}/>
                    </Row>
                    <Row gutter={[16, 16]}>
                        {availableIntegrations.map(integration => (
                            <Col xs={24} sm={12} md={8} lg={6} key={integration.id}>
                                <div style={{ cursor: 'pointer' }}>
                                <Card
                                    onClick={() => handleCardClick(integration)}
                                    title={integration.name}
                                    cover={
                                      integration.platform === 'Custom Read Integration' ? <ToolOutlined style={{fontSize: 100, paddingTop: 20}} />  : (integration.name === 'Email Inbox' ? <MailOutlined style={{fontSize: 100, paddingTop: 20}}/>: <img alt={integration.name} src={integration.icon_url} style={{height: '100px', objectFit: 'contain', margin: '10px auto'}}/>)
                                    }
                                >
                                    {/*<Card.Meta*/}
                                    {/*    description={integration.type}*/}
                                    {/*/>*/}
                                </Card>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </>
            }
        </div>
    );
}

export default Integrations;
