import React from 'react';
import { Form, Input, Col, Row } from 'antd';

const NewShipToAddressForm = ({ newAddressForm = {}, handleAddressChange, requiredFieldLabel }) => {
    const requiredBillingFields = ['LINE_1', 'LINE_2', 'CITY', 'STATE', 'POSTAL_CODE', 'COUNTRY'];

    const anyFieldHasValue = requiredBillingFields.some(field => newAddressForm[field]);

    return (
        <Form layout="vertical">
            <Row gutter={[15, 0]}>
                <Col span={24} key={'LINE_1'}>
                    <Form.Item
                        label={requiredFieldLabel('Attn')}
                        validateStatus={anyFieldHasValue && !newAddressForm.LINE_1 ? 'error' : ''}
                        help={anyFieldHasValue && !newAddressForm.LINE_1 ? 'This field is required' : ''}
                    >
                        <Input
                            value={newAddressForm.LINE_1 || ''}
                            onChange={e => handleAddressChange(e, 'LINE_1')}
                            placeholder="Attn..."
                        />
                    </Form.Item>
                </Col>
                <Col span={24} key={'LINE_2'}>
                    <Form.Item
                        label={requiredFieldLabel('Street Address')}
                        validateStatus={anyFieldHasValue && !newAddressForm.LINE_2 ? 'error' : ''}
                        help={anyFieldHasValue && !newAddressForm.LINE_2 ? 'This field is required' : ''}
                    >
                        <Input
                            value={newAddressForm.LINE_2 || ''}
                            onChange={e => handleAddressChange(e, 'LINE_2')}
                            placeholder="Street address..."
                        />
                    </Form.Item>
                </Col>
                <Col span={12} key={'CITY'}>
                    <Form.Item
                        label={requiredFieldLabel('City')}
                        validateStatus={anyFieldHasValue && !newAddressForm.CITY ? 'error' : ''}
                        help={anyFieldHasValue && !newAddressForm.CITY ? 'This field is required' : ''}
                    >
                        <Input
                            value={newAddressForm.CITY || ''}
                            onChange={e => handleAddressChange(e, 'CITY')}
                            placeholder="City..."
                        />
                    </Form.Item>
                </Col>
                <Col span={12} key={'STATE'}>
                    <Form.Item
                        label={requiredFieldLabel('State')}
                        validateStatus={anyFieldHasValue && !newAddressForm.STATE ? 'error' : ''}
                        help={anyFieldHasValue && !newAddressForm.STATE ? 'This field is required' : ''}
                    >
                        <Input
                            value={newAddressForm.STATE || ''}
                            onChange={e => handleAddressChange(e, 'STATE')}
                            placeholder="State or region..."
                        />
                    </Form.Item>
                </Col>
                <Col span={12} key={'POSTAL_CODE'}>
                    <Form.Item
                        label={requiredFieldLabel('Postal Code')}
                        validateStatus={anyFieldHasValue && !newAddressForm.POSTAL_CODE ? 'error' : ''}
                        help={anyFieldHasValue && !newAddressForm.POSTAL_CODE ? 'This field is required' : ''}
                    >
                        <Input
                            value={newAddressForm.POSTAL_CODE || ''}
                            onChange={e => handleAddressChange(e, 'POSTAL_CODE')}
                            placeholder="Postal code..."
                        />
                    </Form.Item>
                </Col>
                <Col span={12} key={'COUNTRY'}>
                    <Form.Item
                        label={requiredFieldLabel('Country')}
                        validateStatus={anyFieldHasValue && !newAddressForm.COUNTRY ? 'error' : ''}
                        help={anyFieldHasValue && !newAddressForm.COUNTRY ? 'This field is required' : ''}
                    >
                        <Input
                            value={newAddressForm.COUNTRY || ''}
                            onChange={e => handleAddressChange(e, 'COUNTRY')}
                            placeholder="Country..."
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default NewShipToAddressForm;