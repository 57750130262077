// EmailEditor.jsx
import React, { useState, useRef, useEffect } from 'react';
import { Modal, Input, Tag, Button, Alert, Tabs, Skeleton, Typography, Row } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { fetchThreadsData } from "../../endpoints/fetchThreadsData";
import CheckmarkAnimation from '../CheckmarkAnimation';

const { TabPane } = Tabs;

// Utility function to validate email format
const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const EmailEditor = ({ 
    visible, 
    onClose, 
    userData, 
    orderId, 
    poNum = null, 
    summary = null, 
    to = null, 
    handleSendEmailOverride = null, 
    externalLoading = null, 
    externalSuccess = null, 
    isInvoice = false 
}) => {
    // Recipients state: Array of objects { email: string, valid: boolean }
    const [recipients, setRecipients] = useState([]);
    const [recipientInput, setRecipientInput] = useState('');
    const [messageBody, setMessageBody] = useState(isInvoice ? '' : '');
    const [subject, setSubject] = useState(poNum ? (isInvoice ? `Invoice - PO# ${poNum}` : `Order Summary - PO# ${poNum}`) : 'Order Summary');
    const [loading, setLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showFailed, setShowFailed] = useState(null);
    const [invoiceHTML, setInvoiceHTML] = useState('');
    const [invoiceError, setInvoiceError] = useState(null);

    // CC Recipients state: Similar structure as recipients
    const [ccRecipients, setCcRecipients] = useState([]);
    const [ccInput, setCcInput] = useState('');

    const inputRef = useRef(null);
    const ccInputRef = useRef(null);

    useEffect(() => {
        if (!visible) {
            // Reset all fields when modal is closed
            setRecipients([]);
            setRecipientInput('');
            setCcRecipients([]);
            setCcInput('');
            setMessageBody('');
            setSubject('');
            setShowSuccess(false);
            setShowFailed(null);
            setInvoiceHTML('');
            setInvoiceError(null);
        } else {
            // Initialize fields when modal is opened
            if (to && to.length > 0 && to[0]) setRecipients(to.map(email => ({ email, valid: validateEmail(email) })));
            setSubject(poNum ? (isInvoice ? `Invoice - PO# ${poNum}` : `Order Summary - PO# ${poNum}`) : 'Order Summary');
            if (isInvoice) {
                fetchInvoiceHTML();
            } else {
                setMessageBody(summary);
            }
        }
    }, [visible, poNum, summary, isInvoice, to]);

    const fetchInvoiceHTML = async () => {
        setLoading(true);
        try {
            const response = await fetchThreadsData(userData.accessToken, {
                info_type: 'get_invoice_html',
                order_id: orderId,
            });
            if (response.html) {
                setInvoiceHTML(response.html);
                setMessageBody(''); // Clear the message body since we'll display the invoice
            } else {
                setInvoiceError('Failed to load invoice preview.');
            }
        } catch (error) {
            console.error('Error fetching invoice HTML:', error);
            setInvoiceError('Error fetching invoice preview.');
        } finally {
            setLoading(false);
        }
    };

    const handleSendEmail = async () => {
        setLoading(true);
        try {
            const payload = isInvoice
                ? {
                    info_type: 'send_invoice_email',
                    to_emails: recipients.map(r => r.email),
                    subject: subject,
                    order_id: orderId,
                    cc: ccRecipients.map(cc => cc.email)
                }
                : {
                    info_type: 'send_email',
                    to_emails: recipients.map(r => r.email),
                    subject: subject,
                    message_body: messageBody,
                    order_id: orderId,
                    cc: ccRecipients.map(cc => cc.email)
                };

            const response = await fetchThreadsData(userData.accessToken, payload);
            if (response.success) {
                setShowSuccess(true);
                setLoading(false);
                setTimeout(() => {
                    onClose(response.props);
                }, 1000);
            } else {
                setShowFailed(response.message);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error sending email:', error);
            setShowFailed('Error sending email.');
            setLoading(false);
        }
    };

    // Handle adding to "To" recipients
    const handleRecipientInputChange = (e) => {
        setRecipientInput(e.target.value);
    };

    const handleInputConfirm = () => {
        const trimmedInput = recipientInput.trim();
        if (trimmedInput) {
            const isValid = validateEmail(trimmedInput);
            setRecipients([...recipients, { email: trimmedInput, valid: isValid }]);
            setRecipientInput('');
        }
    };

    const handleRemoveRecipient = (removedEmail) => {
        const newRecipients = recipients.filter(r => r.email !== removedEmail);
        setRecipients(newRecipients);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleInputConfirm();
            e.preventDefault();
        }
    };

    const handleBlur = () => {
        handleInputConfirm();
    };

    // Handle adding to "CC" recipients
    const handleCcInputChange = (e) => {
        setCcInput(e.target.value);
    };

    const handleCcInputConfirm = () => {
        const trimmedInput = ccInput.trim();
        if (trimmedInput) {
            const isValid = validateEmail(trimmedInput);
            setCcRecipients([...ccRecipients, { email: trimmedInput, valid: isValid }]);
            setCcInput('');
        }
    };

    const handleRemoveCcRecipient = (removedEmail) => {
        const newCcRecipients = ccRecipients.filter(cc => cc.email !== removedEmail);
        setCcRecipients(newCcRecipients);
    };

    const handleCcKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleCcInputConfirm();
            e.preventDefault();
        }
    };

    const handleCcBlur = () => {
        handleCcInputConfirm();
    };

    // Determine if there are any invalid emails
    const hasInvalidEmails = () => {
        const invalidTo = recipients.some(r => !r.valid);
        const invalidCc = ccRecipients.some(cc => !cc.valid);
        return invalidTo || invalidCc;
    };

    return (
        <Modal
            title={isInvoice ? "Generate Invoice" : "Email Order Summary"}
            visible={visible}
            onCancel={onClose}
            width={800}
            footer={[
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Button key="cancel" onClick={onClose}>Cancel</Button>
                    <Button
                        key="send"
                        type="primary"
                        onClick={handleSendEmail}
                        disabled={externalLoading || loading || recipients.length === 0 || hasInvalidEmails()}
                    >
                        {(loading || externalLoading) ? <LoadingOutlined /> : 'Send Email'}
                    </Button>
                </div>
            ]}
        >
            {isInvoice && <div>
                <Row>
                    <Typography.Text>Set order status to</Typography.Text>
                </Row>
                <Row>
                    <Typography.Text> <Tag color='yellow'>Closed</Tag>Invoiced</Typography.Text>
                </Row>
            </div>}
            { !(showSuccess || externalSuccess) &&
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Message" key="1">
                        {/* To Recipients */}
                        <div style={{ marginBottom: '10px' }}>
                            <Input
                                addonBefore="to"
                                value={recipientInput}
                                onChange={handleRecipientInputChange}
                                onKeyDown={handleKeyDown}
                                onBlur={handleBlur}
                                placeholder="Enter recipient email"
                            />
                            <div style={{ marginTop: '5px' }}>
                                {recipients.map((r, index) => (
                                    <Tag
                                        key={index}
                                        closable
                                        onClose={() => handleRemoveRecipient(r.email)}
                                        style={{
                                            marginBottom: '5px',
                                            borderColor: r.valid ? undefined : 'red',
                                            backgroundColor: r.valid ? undefined : '#ffcccc',
                                        }}
                                    >
                                        {r.email}
                                    </Tag>
                                ))}
                            </div>
                        </div>

                        {/* CC Recipients */}
                        <div style={{ marginBottom: '10px' }}>
                            <Input
                                addonBefore="cc"
                                value={ccInput}
                                onChange={handleCcInputChange}
                                onKeyDown={handleCcKeyDown}
                                onBlur={handleCcBlur}
                                placeholder="Enter cc email"
                            />
                            <div style={{ marginTop: '5px' }}>
                                {ccRecipients.map((cc, index) => (
                                    <Tag
                                        key={index}
                                        closable
                                        onClose={() => handleRemoveCcRecipient(cc.email)}
                                        style={{
                                            marginBottom: '5px',
                                            borderColor: cc.valid ? undefined : 'red',
                                            backgroundColor: cc.valid ? undefined : '#ffcccc',
                                        }}
                                    >
                                        {cc.email}
                                    </Tag>
                                ))}
                            </div>
                        </div>

                        {/* Subject */}
                        <div style={{ marginBottom: '10px' }}>
                            <Input
                                addonBefore="Subject"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                placeholder="Enter email subject"
                            />
                        </div>

                        {/* Message Body */}
                        <div>
                            <Input.TextArea
                                rows={10}
                                value={messageBody}
                                onChange={(e) => setMessageBody(e.target.value)}
                                placeholder="Type your message here..."
                            />
                        </div>
                    </TabPane>
                    {isInvoice && (
                        <TabPane tab="Preview PDF Attachment" key="2">
                            {loading ? (
                                <Skeleton active />
                            ) : invoiceError ? (
                                <Alert message={invoiceError} type="error" />
                            ) : (
                                <div 
                                    dangerouslySetInnerHTML={{ __html: invoiceHTML }} 
                                    style={{ maxHeight: '60vh', overflowY: 'auto', padding: '10px' }} 
                                />
                            )}
                        </TabPane>
                    )}
                </Tabs>
            }
            {/* Success and Error Alerts */}
            {(showSuccess || externalSuccess) && <CheckmarkAnimation />}
            {showFailed && (
                <Alert
                    message={showFailed}
                    type="error"
                    showIcon
                    closable
                    style={{
                        position: 'absolute',
                        bottom: 10,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 1000,
                        width: '35%'
                    }}
                />
            )}
            {/* Display a warning if there are invalid emails */}
            {hasInvalidEmails() && (
                <Alert
                    message="Please correct the invalid email addresses before sending."
                    type="error"
                    showIcon
                    closable
                    style={{
                        marginTop: '10px'
                    }}
                />
            )}
        </Modal>
    );

};

export { EmailEditor };