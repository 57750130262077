const serverUrl = process.env.REACT_APP_API_URL;


const getSettings = async (accessToken, to_save) => {
    let headers = {
        Authorization: `Bearer ${accessToken}`,
    }
    const response = await fetch(
        `${serverUrl}/settings?` + new URLSearchParams({
            dummy: 'foo'
        }),
        {
            headers: headers,
            body: JSON.stringify(to_save ? to_save : []),
            method: "POST"
        },
    );

    return await response.json();
};

export {getSettings};
