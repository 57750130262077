import { Col, Layout, Divider, Row, Collapse, Typography, List, Badge, Tabs, Skeleton, Button, Modal, Steps, Tooltip, Descriptions, Tag, Table, Input} from "antd";
import logo from "../../logo.svg";
import { UserOutlined, SearchOutlined, MailOutlined, AlertOutlined, CaretRightOutlined } from "@ant-design/icons";
import React, { useEffect, useState, useMemo } from "react";
import Loading from "../Loading";
import { useNavigate, Outlet, Link, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { EmailContent } from "./EmailContent";
import {OrderEntryForm} from './OrderForm';
import CheckmarkAnimation from "../CheckmarkAnimation"; 
import {convertDayjsToStrings, convertStringsToDayjs, generateOrderSummary} from '../../components/utils';
import Sparkles from "../Sparkles";
import { EmailEditor } from "./SendEmail";
import { detailedCurrencyFormatter } from '../utils';
import { unitDisplayLabels } from '../Settings/OrderSettings';


const { Header, Sider, Content, Footer} = Layout;
const {Text} = Typography;
const {Panel} = Collapse;
const { Step } = Steps;
const { Search } = Input;


const menuColor = '#010F3C'
const contentColor = '#EDF0F8'
const colorPrimary = '#4fad96'

const serverUrl = process.env.REACT_APP_API_URL;

const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
};

const PublicOrder = (props) => {
    const [loading, setLoading] = useState(false);
    const [newOrderState, setNewOrderState] = useState({});
    const [webStoreKey, setWebStoreKey] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [selectedOrderData, setSelectedOrderData] = useState(null);
    const [badKey, setBadKey] = useState(false);
    const [formState, setFormState] = useState({});
    const [dataSource, setDataSource] = useState(null);
    const [activeTabKey, setActiveTabKey] = useState('1');
    const [newOrderOpen, setNewOrderOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [submitResult, setSubmitResult] = useState(null);

    const [searchTerm, setSearchTerm] = useState('');
    const [isFormEdited, setIsFormEdited] = useState(false);
    const [isLineItemsEdited, setIsLineItemsEdited] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isFormValidMessage, setIsFormValidMessage] = useState(null);
    const [currentStep, setCurrentStep] = useState(
        () => JSON.parse(window.localStorage.getItem('pantry-ai-current-step')) || 0
    );
    const [emailModal, setEmailModal] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (badKey || webStoreKey || loading) return;
        
        setLoading(true);

        const urlParams = new URLSearchParams(window.location.search);
        const key = urlParams.get('web_store_key');
        const orderId = urlParams.get('order_id');
        const localKey = window.localStorage.getItem("pantry-ai-web-store-key");

        // use the one just supplied in url param otherwise fallback to local storage
        const useKey = key ?? localKey;

        if (key && localKey && key !== localKey) {
            // heyo! someone is using store for multiple diff vendors (lmao.. one day)

            // wipe any ongoing orders
            window.localStorage.removeItem('pantry-ai-new-order');
            window.localStorage.removeItem('pantry-ai-current-step');
        }

        window.localStorage.setItem("pantry-ai-web-store-key", useKey);
        setWebStoreKey(useKey);

        // load in any in-porgress order that a user has
        const storedState = convertStringsToDayjs(JSON.parse(window.localStorage.getItem("pantry-ai-new-order")));

        if (storedState && Object.keys(storedState).length > 0) {
            setNewOrderState(storedState)
        }

        if (!useKey) {
            setBadKey(true);
            return;
        }

        const payload = {};

        try {
            (async () => {
                const data = await fetch(
                    `${serverUrl}/public_order?` + new URLSearchParams({
                        web_store_key: useKey,
                    }),
                    {
                        method: "POST",
                        body: JSON.stringify(convertDayjsToStrings(payload))
                    },
                )
                const r = await data.json();

                if (r['message'] === 'ok') {
                    // didn't wanna 404 necessarily
                    setBadKey(true);
                    setLoading(false);
                    return;
                }
                
                console.log(r)
                setDataSource(r);
                setLoading(false)
             })();

        } catch (e) {
            setBadKey(true);
            setLoading(false);
        }

        if (orderId) {
            // order id was specified in url, intends to view specific order
            setSearchTerm(orderId);
            selectOrder({'id': orderId});
        }
    }, []);

    useEffect(() => {
        if (!webStoreKey || badKey) return;
        
        setSubmitting(true);
        setFormState({});

        const payload = {};

        (async () => {
            const data = await fetch(
                `${serverUrl}/public_order?` + new URLSearchParams({
                    web_store_key: webStoreKey,
                    order_id: selectedOrder.id
                }),
                {
                    method: "POST",
                    body: JSON.stringify(payload)
                },
            )
            const r = await data.json();

            if (r['message'] === 'ok') {
                // didn't wanna 404 necessarily
                setSubmitting(false);
                return;
            }
            
            setSelectedOrderData(r);
            setSubmitting(false)
        })();

    }, [selectedOrder]);

    useEffect(() => {
        if (!selectedOrderData) return;

        setFormState({
            DOCUMENT_ID: selectedOrderData.order.properties.external_id,
            PURCHASE_DATE: selectedOrderData.order.properties.placed_on ? dayjs(selectedOrderData.order.properties.placed_on) : null,
            STATUS: selectedOrderData.order.properties.status,
            SHIP_TO_MATCHES: selectedOrderData.order.SHIP_TO_MATCHES,
            CUSTOMER_MATCHES: selectedOrderData.order.CUSTOMER_MATCHES,
            FOB: selectedOrderData.order.FOB.value,
            FOB_TYPE: selectedOrderData.order.FOB.type,
            REQUESTED_OR_DUE_SHIP_DATE: selectedOrderData.order.properties.due_date ? dayjs(selectedOrderData.order.properties.due_date) : null,
            SHIPPED_ON: selectedOrderData.order.properties.shipped_on ? dayjs(selectedOrderData.order.properties.shipped_on) : null,
            TOTAL_PRICE: selectedOrderData.order.properties.total_price ?? 0.0,
            NON_PRODUCT_LINE_ITEMS: selectedOrderData.order.properties.financial_line_items ?? [],
            USER_SELECTED_PRODUCT_LINE_ITEMS: selectedOrderData.order.properties.line_items.map(item => ({
                ...item,
                association: item.association,
                key: item.key,
                original_quantity: item.original_quantity,
                original_unit: item.original_unit,
                PRODUCT_DESCRIPTION: item.PRODUCT_DESCRIPTION,
                PRODUCT_QUANTITY: item.PRODUCT_QUANTITY,
                PRODUCT_UNIT: item.PRODUCT_UNIT,
                PRODUCT_PRICE: item.PRODUCT_PRICE
            })),
            NOTES: selectedOrderData.order.properties.notes
        });
        
    }, [selectedOrderData]);

    useEffect(() => {
        // This will run every time `currentStep` changes, saving the new step to local storage
        window.localStorage.setItem('pantry-ai-current-step', JSON.stringify(currentStep));
    }, [currentStep]);

    useEffect(() => {
        if (Object.keys(newOrderState) === 0) return;

        // incase an order is interrupted, can come back to it
        window.localStorage.setItem("pantry-ai-new-order", JSON.stringify(newOrderState));

        let isValidState = newOrderState.USER_SELECTED_PRODUCT_LINE_ITEMS && 
            newOrderState.USER_SELECTED_PRODUCT_LINE_ITEMS.length > 0 &&
            isValidEmail(newOrderState.BUYER_CONTACT_EMAIL) &&
            newOrderState.USER_SELECTED_PRODUCT_LINE_ITEMS.every(lineItem => lineItem.association) &&
            newOrderState.USER_SELECTED_PRODUCT_LINE_ITEMS.every(lineItem => lineItem.PRODUCT_QUANTITY) &&
            (
                (newOrderState.CUSTOMER_MATCHES.type === 'match' && newOrderState.CUSTOMER_MATCHES.id) ||
                (newOrderState.CUSTOMER_MATCHES.type === 'new' && newOrderState.CUSTOMER_MATCHES.name)
            );

        let message = null;

        if (!isValidState) {
            message = 'Line items or ship-to needs attention.';
        }

        // Additional specific check for email validity
        if (newOrderState.BUYER_CONTACT_EMAIL && !isValidEmail(newOrderState.BUYER_CONTACT_EMAIL)) {
            message = 'A valid contact email is required.';
        }

        setIsFormValidMessage(message);
        setIsFormValid(isValidState && !message); // Update isFormValid based on all checks

    }, [newOrderState]);

    const selectOrder = (order) => {
        setSelectedOrder(order);
        //setSelectedOrderData(null);

        // Create a new instance of URLSearchParams based on current search
        const newSearchParams = new URLSearchParams(location.search);

        // Set the order_id parameter
        newSearchParams.set('order_id', order.id);

        // Update the URL with new search parameters without reloading the page
        navigate({
            pathname: location.pathname,
            search: newSearchParams.toString()
        }, { replace: true });
    };

    const filteredOrders = useMemo(() => {
        if (!searchTerm || searchTerm === '') return (dataSource?.orders ?? []);

 
        const result = dataSource?.orders.filter(order => {
            const shipToName = dataSource.ship_to.other_options.find(s => s.id === order.ship_to_id)?.name.toLowerCase();
            return order.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
                   order.external_id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                   shipToName?.includes(searchTerm.toLowerCase());
        })

        return result;
    }, [dataSource, searchTerm]); 

    const getList = (list_type) => {
        const ordersToShow = filteredOrders.filter(o =>
            (list_type === 'pastOrders' && !o.recommended) || (list_type === 'recommended' && o.recommended)
        );

        const listName = list_type === 'recommended' ? 'Recommended' : 'Past Orders';

        const header = (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text strong style={{ flex: 1 }}>
                    {listName}
                </Text>
            </div>
        );

        return (
            <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                style={{background: 'white'}}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="site-collapse-custom-collapse"
            >
                <Panel header={header} key="1" className="site-collapse-custom-panel">
                    <List
                        locale={{emptyText: list_type === 'recommended' ? 'You need at least 2 past orders for recommendations' : 'No orders match your search...'}}
                        itemLayout="horizontal"
                        dataSource={ordersToShow}
                        renderItem={(order) => (
                            <List.Item
                                key={`${order.id}`}
                                onClick={() => selectOrder(order)}
                                style={{
                                    paddingLeft: '10px',
                                    paddingRight: '5px',
                                    backgroundColor: selectedOrder && selectedOrder.id === order.id ? '#e6f7ff' : 'transparent',
                                    cursor: 'pointer',
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
                                }}
                            >
                                <List.Item.Meta
                                    title={
                                        <Row>
                                            <Col flex={'3px'}>
                                                {order.recommended && (
                                                    <Badge dot style={{ marginRight: 8 }} />
                                                )}
                                            </Col>
                                            <Col flex={'200px'} style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                <Text strong>{'Order #: '+(order.external_id ?? 'N/A')}</Text>
                                            </Col>
                                            <Col flex='auto'/>
                                            <Col>
                                                <Text style={{ fontSize: '12px', paddingRight: 5}}>{dayjs(order.created_at).format('MM/DD')}</Text>
                                            </Col>
                                        </Row>
                                    }
                                    description={dataSource.ship_to.other_options.find((s) => s.id === order.ship_to_id)['name']}
                                />
                            </List.Item>
                        )}
                    />
                </Panel>
            </Collapse>
        );
    };

    const handleProductChange = (lineIndex, field, value) => {
        // update customer-specific pricing
        const newLineItems = newOrderState.USER_SELECTED_PRODUCT_LINE_ITEMS.map((item) => {
            if (item.association) {
                const product = dataSource.products.find(p => p.uuid === item.association);
                // customer price if one exists, else default price
                const productPrice = (newOrderState.CUSTOMER_MATCHES.config?.productOverrides ?? {})[product.uuid]?.price ?? product.price;
                return {...item, 'PRODUCT_PRICE': productPrice}
            } else return {}
        });

        const updatedProductLineItems = newLineItems.map((item, index) => {
            if (['PRODUCT_QUANTITY', 'PRODUCT_PRICE', 'PRODUCT_UNIT'].includes(field) && index === lineIndex) {
                return {...item, [field]: value}
            } else if (index === lineIndex) {
                // association handled specially
                return { ...item, association: field };
            }
            return item;
        });

        if (!isFormEdited) {
            setIsFormEdited(true);
            setIsLineItemsEdited(true);
        }

        setNewOrderState(prevState => ({
            ...prevState,
            USER_SELECTED_PRODUCT_LINE_ITEMS: updatedProductLineItems,
        }));
    };

    const handleDeleteLineItem = (lineIndex) => {
        if (!isFormEdited) {
            setIsFormEdited(true);
            setIsLineItemsEdited(true);
        }

        const updatedProductLineItems = newOrderState.USER_SELECTED_PRODUCT_LINE_ITEMS.filter((item, index) => index !== lineIndex);
    
        setNewOrderState(prevState => ({
            ...prevState,
            USER_SELECTED_PRODUCT_LINE_ITEMS: updatedProductLineItems,
        }));
    };

    const handleAddLineItem = () => {
        if (!isFormEdited) {
            setIsFormEdited(true);
            setIsLineItemsEdited(true);
        }

        const newLineItem = { PRODUCT_QUANTITY: 0, PRODUCT_PRICE: 0, association: null}; 
    
        setNewOrderState(prevState => ({
            ...prevState,
            USER_SELECTED_PRODUCT_LINE_ITEMS: [...prevState.USER_SELECTED_PRODUCT_LINE_ITEMS, newLineItem],
        }));
    };

    const startNewOrder = () => {
        if (Object.keys(newOrderState).length === 0) {
            setCurrentStep(0);
            
            setNewOrderState({
                DOCUMENT_ID: '',
                PURCHASE_DATE: dayjs(),
                STATUS: 'New',
                SHIP_TO_MATCHES: dataSource.ship_to,
                CUSTOMER_MATCHES: dataSource.customer,
                FOB: dataSource.customer.FOB,
                FOB_TYPE: 'Customer Config',
                REQUESTED_OR_DUE_SHIP_DATE: null,
                SHIPPED_ON: null,
                TOTAL_PRICE: 0.0,
                NON_PRODUCT_LINE_ITEMS: [],
                USER_SELECTED_PRODUCT_LINE_ITEMS: [],
                NOTES: ''
            });
        }
        setNewOrderOpen(true);
    }

    const cancelNewOrder = () => {
        setNewOrderOpen(false);
    }

    const completeNewOrder = () => {
        setNewOrderOpen(false);
        setNewOrderState({});
        setSubmitResult(null);

        window.localStorage.removeItem('pantry-ai-new-order');
        window.localStorage.removeItem('pantry-ai-current-step');
    }

    const handleFormChange = (field, value) => {
        setNewOrderState(prevState => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleSendEmail = async (messageBody, recipients, subject, orderId) => {
        setSubmitting(true);

        const payload = JSON.stringify({'info_type': 'send_email', 'message_body': messageBody, 'to_emails': recipients, 'subject': subject, 'order_id': orderId});

        try {
            const data = await fetch(
                `${serverUrl}/public_order?` + new URLSearchParams({
                    web_store_key: webStoreKey,
                    submit_email: true
                }),
                {
                    method: "POST",
                    body: payload
                },
            )
            const r = await data.json();
            console.log(r)
            if (r['message'] === 'ok') {
                // didn't wanna 404 necessarily
                setLoading(false);
                return;
            }
            
            // returns fresh data with new order in it
            setSelectedOrderData(r);
            setSubmitResult(r['order_result']['order_id']);
        } catch (e) {
            setSubmitResult('failure');
        }

        setSubmitting(false);
        setTimeout(() => {
            closeEmail();      
        }, 1000); 
    }

    const closeEmail = () => {
        setEmailModal(false);
        setSubmitResult(null);
    }

    const submitOrder = async () => {
        setSubmitting(true);

        const payload = JSON.stringify(convertDayjsToStrings({
            ...newOrderState,
            orderSummary: generateOrderSummary(newOrderState, dataSource, unitDisplayLabels)
        }));

        try {
            const data = await fetch(
                `${serverUrl}/public_order?` + new URLSearchParams({
                    web_store_key: webStoreKey,
                    submit: true
                }),
                {
                    method: "POST",
                    body: payload
                },
            )
            const r = await data.json();
            
            if (r['message'] === 'ok') {
                // didn't wanna 404 necessarily
                setLoading(false);
                return;
            }

            console.log(r)
            
            if (r['order_result']['error']) {
                setSubmitResult('failure');
            } else {
                // returns fresh data with new order in it
                setDataSource(r);
                setSubmitResult(r['order_result']['order_id']);
            }
        } catch (e) {
            setSubmitResult('failure');
        }

        setSubmitting(false);
    } 

    const nextStep = () => {
        if (currentStep < 2) {
            if (currentStep === 1) {
                // kick to backend
                submitOrder()
            }
            setCurrentStep(currentStep + 1);
        }
    };

    // Function to move to the previous step
    const previousStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    return (
        <Layout className="layout">
            <EmailEditor 
                visible={emailModal} 
                to={dataSource?.alert_emails ?? []}
                orderId={selectedOrder?.id}
                onClose={closeEmail}
                poNum={formState.DOCUMENT_ID}
                handleSendEmailOverride={handleSendEmail}
                externalSuccess={submitResult}
                externalLoading={submitting}
                summary={() => "Hi Team, I was just wondering..."}
            />
            {dataSource && <Modal
                width={1000}
                title=" "
                open={newOrderOpen}
                onOk={nextStep}
                onCancel={cancelNewOrder}
                footer={
                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: 10, marginTop: 'auto' }}>
                        <Button danger onClick={cancelNewOrder}>
                            Cancel
                        </Button>
                        <div>
                        {currentStep > 0 && (
                            <Button key="back" style={{paddingRight: 15}} onClick={previousStep} disabled={submitResult || submitting}>
                                Back
                            </Button>
                        )}
                        {currentStep < 1 && (
                            <Tooltip placement="topRight" title={isFormValidMessage}>
                                <Button key="next" type="primary" onClick={nextStep} disabled={!isFormValid}>
                                    Next
                                </Button>
                            </Tooltip>
                        )}
                        {currentStep == 2  && (
                            <Tooltip placement="topRight" title={'All set!'}>
                                <Button key="next" type="primary" onClick={completeNewOrder} disabled={submitting}>
                                    Complete
                                </Button>
                            </Tooltip>
                        )}
                        {currentStep === 1 && (
                            <Button key="submit" type="primary" onClick={nextStep}>
                                Submit Order
                            </Button>
                        )}
                    </div>
                    </div>
                }
            >
                <Steps current={currentStep}>
                    <Step title="Order Form" />
                    <Step title="Review & Submit" />
                    <Step title="Summary" />
                </Steps>
                <div style={{ marginTop: 20 }}>
                    {currentStep === 0 && (
                        Object.keys(newOrderState).length > 0 && <OrderEntryForm
                            formState={newOrderState}
                            handleFormChange={handleFormChange}
                            handleProductChange={handleProductChange}
                            handleAddLineItem={handleAddLineItem}
                            handleDeleteLineItem={handleDeleteLineItem}
                            userData={{webKey: webStoreKey}}
                            workflowState={{'products': dataSource?.products ?? [], 'generatedOrder': newOrderState}}
                            allowConfigOverride={false}
                            readOnly={false}
                            external={true}
                            fullscreen={true}
                        />
                    )}
                    {currentStep === 1 && (
                        <div >
                        <Text>Review your order and submit it to {dataSource.account_name} - they will contact you if any additional details are necessary.</Text>
                        <br/>
                        <br/>
                        <Descriptions layout="vertical" bordered size="small" column={1}>
                            {newOrderState.DOCUMENT_ID && <Descriptions.Item label="Order #">{newOrderState.DOCUMENT_ID}</Descriptions.Item>}
                            {newOrderState.BUYER_CONTACT_PHONE && <Descriptions.Item label="Contact Phone">{newOrderState.BUYER_CONTACT_PHONE}</Descriptions.Item>}
                            {newOrderState.BUYER_CONTACT_EMAIL && <Descriptions.Item label="Contact Email">{newOrderState.BUYER_CONTACT_EMAIL}</Descriptions.Item>}
                            {newOrderState.BUYER_CONTACT_NAME && <Descriptions.Item label="Contact Name">{newOrderState.BUYER_CONTACT_NAME}</Descriptions.Item>}
                            {newOrderState.REQUESTED_OR_DUE_SHIP_DATE && <Descriptions.Item label="Due On">{dayjs(newOrderState.REQUESTED_OR_DUE_SHIP_DATE).format('YYYY-MM-DD')}</Descriptions.Item>}
                            {newOrderState.REQUESTED_OR_DUE_SHIP_DATE === null && <Descriptions.Item label="Due On">ASAP</Descriptions.Item>}
                        </Descriptions>
                        <Typography.Text strong>Customer Details:</Typography.Text>
                        <Descriptions layout="vertical" bordered size="small" column={1}>
                            <Descriptions.Item label="Customer">
                                {newOrderState.CUSTOMER_MATCHES?.type === 'new' && 
                                    <Tag color="blue">Create New</Tag>
                                }
                                {newOrderState.CUSTOMER_MATCHES?.name}
                            </Descriptions.Item>
                            <Descriptions.Item label="Ship To">
                                {(!newOrderState.SHIP_TO_MATCHES?.name || !newOrderState.SHIP_TO_MATCHES?.name.trim()) && 
                                    <Tag color="grey">Skip</Tag>
                                }
                                {!(!newOrderState.SHIP_TO_MATCHES?.name || !newOrderState.SHIP_TO_MATCHES?.name.trim()) && newOrderState.SHIP_TO_MATCHES?.type === 'new' && 
                                    <Tag color="blue">Create New</Tag>
                                }
                                {newOrderState.SHIP_TO_MATCHES?.name}
                            </Descriptions.Item>
                        </Descriptions>
                        {newOrderState.USER_SELECTED_PRODUCT_LINE_ITEMS && (
                            <Table
                                dataSource={newOrderState.USER_SELECTED_PRODUCT_LINE_ITEMS.map((line, index) => ({
                                    key: index,
                                    product: dataSource?.products.find(product => product.uuid === line.association)?.name || 'Unknown Product',
                                    ...line
                                }))}
                                columns={[
                                    {
                                        title: 'Product',
                                        dataIndex: 'product',
                                        key: 'product',
                                    },
                                    {
                                        title: 'Quanitity',
                                        dataIndex: 'PRODUCT_QUANTITY',
                                        key: 'PRODUCT_QUANTITY',
                                    },
                                    {
                                        title: 'Unit',
                                        dataIndex: 'PRODUCT_UNIT',
                                        key: 'PRODUCT_UNIT',
                                        render: (val) => {
                                            return unitDisplayLabels[val];
                                        }
                                    },
                                    {
                                        title: 'Price',
                                        dataIndex: 'PRODUCT_PRICE',
                                        key: 'PRODUCT_PRICE',
                                        render: (val) => {
                                            return detailedCurrencyFormatter.format(val);
                                        }
                                    },
                                ]}
                                pagination={false}
                                size="small"
                            />
                        )}
                        {newOrderState.NON_PRODUCT_LINE_ITEMS.length > 0 && (
                            <Table
                                dataSource={newOrderState.NON_PRODUCT_LINE_ITEMS}
                                columns={[
                                    {
                                        title: 'Description',
                                        dataIndex: 'DESCRIPTION',
                                        key: 'DESCRIPTION',
                                    },
                                    {
                                        title: 'Total',
                                        dataIndex: 'TOTAL',
                                        key: 'TOTAL',
                                        render: (val) => {
                                            return detailedCurrencyFormatter.format(val);
                                        }
                                    },
                                ]}
                                pagination={false}
                                size="small"
                            />
                        )}
                        <Descriptions layout="vertical" bordered size="small" column={1}>
                            {newOrderState.TOTAL_PRICE !== null && <Descriptions.Item label="Total">{detailedCurrencyFormatter.format(newOrderState.TOTAL_PRICE)}</Descriptions.Item>}
                            {newOrderState.NOTES && <Descriptions.Item label="Notes">{newOrderState.NOTES}</Descriptions.Item>}
                        </Descriptions>
                    </div>
                    )}
                    {currentStep === 2 && (
                        <div style={{height: 400}}>
                            {submitting && !submitResult && <Loading style={{paddingTop: 0}}/>}
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%', // Adjust based on your modal height or desired spacing
                            }}>
                                {!submitting && submitResult && submitResult !== 'failure' && <div>
                                    <CheckmarkAnimation />
                                    <br/>
                                    <Typography.Text strong style={{ marginTop: 20, textAlign: 'center' }}>
                                        Your order has been received by {dataSource.account_name}!
                                    </Typography.Text>
                                    <br/>
                                    <Typography.Text style={{ textAlign: 'center' }}>
                                        Track it's progress and message the team 
                                        <Link 
                                            onClick={() => {
                                                completeNewOrder();
                                                window.location.href = `/order?web_store_key=${webStoreKey}&order_id=${submitResult}`;
                                            }}
                                            to={{
                                                pathname: '/order', 
                                                search: `?web_store_key=${webStoreKey}&order_id=${submitResult}`,
                                            }}
                                        > here</Link>
                                    </Typography.Text>
                                </div>}
                                {!submitting && submitResult && submitResult === 'failure' && <div>
                                    <br/>
                                    <Typography.Text strong style={{ marginTop: 20, textAlign: 'center' }}>
                                        {dataSource.account_name} can't accept orders at the moment, try again later or contact them directly
                                    </Typography.Text>
                                </div>
                                }
                            </div>
                        </div>
                    )}
                </div>
            </Modal>}
           <Header className={'nav'} style={{padding: 0}}>
                <Row style={{ background: menuColor }}>
                    <Col flex="70px">
                        <a href="https://pantry.ai">
                            <img className="logo" height={'45'} src={logo} alt="Logo" />
                        </a>
                    </Col>
                    <Col flex="115px" style={{ minWidth: 100 }}>
                        <a href="https://pantry.ai">
                            <Typography.Title style={{ color: contentColor, fontSize: 20, marginBottom: 30  }}>
                                Pantry AI
                            </Typography.Title>
                        </a>
                    </Col>
                    <Col flex="auto" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {!badKey && dataSource &&
                            <Typography.Title style={{ color: contentColor, fontSize: 20, margin: 0, marginBottom: 15 }}>
                                {dataSource.account_name} Web Store - {dataSource.customer.name}
                            </Typography.Title>
                        }
                    </Col>
                    <Col flex="185px">
                        {!badKey && dataSource &&
                            <Button type="primary" onClick={startNewOrder}>
                                {Object.keys(newOrderState).length === 0 ? "New Order" : "Resume Order"}
                            </Button>
                        }
                    </Col>
                </Row>
            </Header>
            <Layout style={{ 'overflow': 'auto'}}>
            {!badKey && (
                    <Sider width='25%' theme="light">
                        <div style={{'overflowY': 'auto', 'height': '95vh'}}>
                            <Search
                                placeholder="Search Orders..."
                                value={searchTerm}
                                allowClear
                                onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
                                style={{ 
                                    padding: 15, 
                                    width: '100%', 
                                    
                                 }}
                            />
                        {dataSource && getList('recommended')}
                        {!dataSource ? (
                            <Skeleton style={{padding: 15}} paragraph={{ rows: 15 }} active />
                        ) : (
                            getList('pastOrders')
                        )}
                        </div>
                    </Sider>
                )}
            <Content style={{ 'overflowY': 'auto', 'height': '95vh'}}>
                {loading && !submitting && <Loading />}
                {badKey && <div style={{marginTop: 30}}>
                        <Row style={{ justifyContent: 'center', alignItems: 'center', fontSize: 20 }}>
                            <SearchOutlined style={{fontSize: 20, marginRight: 5}}/>
                            <Typography.Text style={{fontSize: 20}}> No access token found!</Typography.Text>
                        </Row>
                        <Row style={{ justifyContent: 'center', alignItems: 'center', fontSize: 20}}>
                                Ask your vendor for a personalized web store link.
                        </Row>
                        
                    </div>
                }
                {!badKey && !selectedOrder && !loading && dataSource && <div style={{marginTop: 30}}>
                        <Row style={{ justifyContent: 'center', alignItems: 'center', fontSize: 20, paddingTop: 200}}>
                            <Typography.Text style={{fontSize: 20}}> Welcome to your personalized web store for {dataSource.account_name} products.</Typography.Text>
                        </Row>
                        <Row style={{ justifyContent: 'center', alignItems: 'center', fontSize: 20}}>
                                Check on existing orders, or place a new one!
                        </Row>
                        
                    </div>
                }
                {!badKey && selectedOrder && (
                    <div style={{paddingLeft: 15, paddingRight: 15}}>
                        <Tabs
                            defaultActiveKey="1"
                            activeKey={activeTabKey}
                            onChange={setActiveTabKey}
                            tabBarExtraContent={{
                                right: activeTabKey === '2' ? (
                                    <Button onClick={() => setEmailModal(true)} icon={<MailOutlined />} style={{ marginTop: 15, marginRight: 15 }}>
                                        Message Team
                                    </Button>
                                ) : undefined
                            }}
                            items={[
                                {
                                    key: '1',
                                    label: 'Details',
                                    children: (
                                        <div style={{ padding: 15, background: 'white', borderRadius: '10px', minHeight: '500px'}}>
                                            <OrderEntryForm
                                                formState={formState}
                                                handleFormChange={() => {}}
                                                handleProductChange={() => {}}
                                                handleAddLineItem={() => {}}
                                                handleDeleteLineItem={() => {}}
                                                userData={{ webKey: webStoreKey }}
                                                workflowState={{ 'products': selectedOrderData?.products, 'generatedOrder': formState }}
                                                allowConfigOverride={true}
                                                readOnly={true}
                                                fullscreen={true}
                                            />
                                        </div>
                                    )
                                },
                                {
                                    key: '2',
                                    label: 'Messages',
                                    children: selectedOrderData?.assoc?.sort((a, b) => new Date(b.datetime) - new Date(a.datetime)).map((message, index) => (
                                        <EmailContent
                                            key={index}
                                            message={message}
                                            onClick={() => {}}
                                            lastDismissed={message.unread ? undefined : dayjs()}
                                        />
                                    ))
                                }
                            ]}
                        />
                    </div>
                )}
            </Content>
            </Layout>
            <Divider style={{ margin: 0, borderColor: 'black' }} /> 
            <Footer style={{ textAlign: 'center', background: 'white' }}>Pantry Software ©2024</Footer>
        </Layout>
    );
}

export default PublicOrder;