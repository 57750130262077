import {Button, Col, Layout, Popover, Row, Steps, Typography} from "antd";
import logo from "../../logo.svg";
import AuthNav from "../../auth/authNav";
import {UserOutlined} from "@ant-design/icons";
import {Content, Footer} from "antd/es/layout/layout";
import React, {useState} from "react";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../Loading";


const menuColor = '#010F3C'
const contentColor = '#EDF0F8'
const colorPrimary = '#4fad96'

const Processing = (props) => {
    const {userData} = props;

    return (
        <Layout className="layout">
            <Row className={'nav'} wrap={false}>
                <Col flex="90px" style={{minWidth: 90, background: menuColor, color: contentColor, fontSize: 24}}>
                    <img className="logo" height={'45'} src={logo} alt="Logo"/>
                </Col>
                <Col flex="115px" className={"logo-name"}
                     style={{minWidth: 115, background: menuColor, color: contentColor, fontSize: 24}}>
                    Pantry
                </Col>
                <Col flex="auto" style={{background: menuColor}}>
                </Col>
                <Col flex="120px" style={{background: menuColor}}>
                    <AuthNav userData={userData}/>
                </Col>
            </Row>
            <Content style={{background: menuColor, paddingTop: 50, height: '100vh'}}>
                <Row align="center">
                    <img height={'150'} src={logo} alt="Logo"/>
                </Row>
                <Row align="center">
                    <Typography.Title style={{fontSize: 28, color: 'white'}}>Pantry is crunching the numbers</Typography.Title>
                </Row>
                <Row align="center">
                    <Typography.Title style={{fontSize: 18, color: 'white'}}>You will get product access once you complete the onboarding meeting and we analyze your data.</Typography.Title>
                </Row>
                <br/>
                <br/>
                <Row align="center">
                    <Button
                        size={"large"}
                        style={{background: colorPrimary, color: "white"}}
                        onClick={() => window.open('https://www.pantrysoftware.com/meetings/meet-with-ian/pantry-onboarding', '_blank')}
                    >Book a Meeting</Button>
                </Row>
            </Content>
            <Footer style={{textAlign: 'center'}}>Pantry Software ©2023</Footer>
        </Layout>
    );
}

export default withAuthenticationRequired(Processing, {
    onRedirecting: () => <Loading/>,
});
